import { createTheme } from '@mui/material';
import { colors } from './constants';

const theme = createTheme({
  typography: {
    fontFamily: 'Manrope',
  },

  palette: {
    primary: {
      main: colors.chamoisee,
    },
  },

  components: {
    MuiTypography: {
      defaultProps: {
        display: 'block',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.colorscheme && {
            color: colors[ownerState.colorscheme]
              ? colors[ownerState.colorscheme]
              : ownerState.colorscheme,
          }),
        }),
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: () => ({
          textTransform: 'none !important',
          fontWeight: '500 !important',
          fontSize: '16px !important',

          '&.MuiButton-sizeMedium': {
            paddingTop: 16,
            paddingBottom: 16,
          },
          '&.MuiButton-sizeSmall': {
            paddingTop: 12,
            paddingBottom: 12,
          },
          '&.MuiButton-contained': {
            boxShadow: 'none !important',
            borderRadius: '8px',
          },
          '&.MuiButton-containedPrimary': {
            color: 'white',
          },
        }),
      },
    },
  },
});

export default theme;
