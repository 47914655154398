import React from 'react';
import { Box, Container, Grid, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { colors } from '../../config/constants';

// import imgWhiteLogo from '../../assets/logos/white-logo.png';
import { NavLink } from 'react-router-dom';

// const LogoImage = styled('img')(() => ({
//   width: 183,
//   height: 68,
//   objectFit: 'contain',
//   display: 'block',
//   marginBottom: 24,
// }));

const FooterSubtitle = styled(Typography)(() => ({
  color: colors.white,
  fontSize: 18,
  fontWeight: 500,
  lineHeight: '24px',
}));

const FooterLink = styled(NavLink)(() => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '22px',
  color: colors['gray.400'],
  marginTop: 6,
  marginBottom: 6,
  textDecoration: 'none !important',
}));

const AppFooter = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: colors['footer.bg'],
        py: 12,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
          >
            {/* <LogoImage
              src={imgWhiteLogo}
              alt="Logo"
            />
            <Typography
              fontSize={14}
              lineHeight="22px"
              colorscheme="gray.300"
            >
              {t('Customer Supports')}
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={500}
              lineHeight={1.5}
              colorscheme="white"
              mt={0.5}
            >
              (629) 555-0129
            </Typography>
            <Typography
              fontSize={14}
              lineHeight="22px"
              colorscheme="gray.300"
              my={1.5}
            >
              4517 Washington Ave. Manchester, Kentucky 39495
            </Typography> */}
            <Typography
              fontSize={16}
              fontWeight={500}
              lineHeight={1.5}
              colorscheme="white"
              mt={0.5}
              mb={2}
            >
              info@oodlmarket.com
            </Typography>
            <img
              alt="logo"
              src="/images/logo.png"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            md={2}
          >
            <FooterSubtitle mb={1.5}>{t('Quick Links')}</FooterSubtitle>
            <Stack>
              <FooterLink to="/#section-about-us">
                <Typography>{t('About Us')}</Typography>
              </FooterLink>
              <FooterLink to="/#section-features">
                <Typography>{t('Features')}</Typography>
              </FooterLink>
              <FooterLink to="/#section-services">
                <Typography>{t('Services')}</Typography>
              </FooterLink>
              <FooterLink to="/privacy-policy">
                <Typography>{t('Privacy Policy')}</Typography>
              </FooterLink>
              <FooterLink to="/terms-and-conditions">
                <Typography>{t('Terms and Conditions')}</Typography>
              </FooterLink>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AppFooter;
