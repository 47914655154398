import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AppMainLayout from '../components/layouts/AppMainLayout';
import LandingPage from '../pages/landing-page';
import PrivacyPolicyPage from '../pages/privacy-policy/PrivacyPolicyPage';
import TermsAndConditionsPage from '../pages/terms-of-use/TermsAndConditionsPage';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<AppMainLayout />}
        >
          <Route
            path=""
            element={<LandingPage />}
            index
          />
          <Route
            path="privacy-policy"
            element={<PrivacyPolicyPage />}
          />
          <Route
            path="terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
