import React, { useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  Link,
} from '@mui/material';

import PageNavigationHeader from './PageNavigationHeader';
import { colors } from '../../config/constants';

const TermsAndConditionsPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Box
      sx={{
        bgcolor: colors.prussianblue,
        color: 'white',
      }}
    >
      <PageNavigationHeader />

      <Container
        sx={{
          '& span.consolas': {
            fontFamily: 'consolas',
          },
        }}
      >
        <Typography
          variant="h4"
          component="h4"
          pb={2}
          pt={23.5}
          textAlign="center"
          fontWeight={700}
        >
          OODL MARKETPLACE SOFTWARES AGREEMENT
          <br />
          GENERAL TERMS AND CONDITIONS
        </Typography>
        <Typography
          component="p"
          mt={2}
        >
          This Agreement (“Agreement”) is entered into by and between you and
          OODL Marketplace LLC (the “Company”), with its registered address at
          112 Capitol Trl Newark Delaware 19711 USA. This Agreement, which
          includes all associated attachments and exhibits, sets forth the terms
          and conditions under which Company agrees to provide you, and you
          agree to obtain access to the OODL Marketplace, online Software and
          database described herein.
        </Typography>
        <Typography
          variant="h4"
          component="h4"
          py={3}
        >
          1. CONSTRUCTION.
        </Typography>
        <Typography component="p">
          <strong>1.1</strong> <u>Construction.</u> Capitalized terms and/or
          defined terms, whether, singular or plural, shall have the meanings
          assigned in the text of this Agreement, including the exhibit(s) and
          attachments set forth.
        </Typography>
        <Typography
          variant="h4"
          component="h4"
          py={3}
        >
          2. SOFTWARE AS A SERVICE.
        </Typography>
        <Typography component="p">
          <strong>
            <u>2.1 Access.</u>
          </strong>{' '}
          Commencing on the Effective Date of this Agreement, the Company shall
          make available to you the OODL Marketplace cloud-based software
          (including any Updates and new versions (collectively, the
          “Software”)) and identify in order for use by you for your internal
          purposes and subject to the scope limitations (e.g., trades under
          transaction) set forth (the “Software”) under the terms of this
          Agreement.
          <br />
          <br />
          <strong>
            <u>2.2 Rights to the Software.</u>
          </strong>{' '}
          Subject to the terms and conditions of this Agreement, the Company
          hereby grants you a non-exclusive, non-transferable, worldwide right
          during the Term to access the Software and permit the number of
          individual users specified by the Company to use the Software solely
          for your own internal business purposes (“Authorized Users”). Unless
          otherwise specified, the term “quantity”, as used, refers to the
          number of Authorized Users that are permitted to access the Software.
          <br />
          <br />
          <strong>
            <u>2.3 Updates.</u>
          </strong>{' '}
          At no charge to you, the Company shall install any software updates
          deemed reasonably necessary on its servers to address errors, bugs or
          other performance issues in the Software (collectively, “Updates”).
          Updates, if any, shall be subject to the same terms and conditions as
          this Agreement.
          <br />
          <br />
          <strong>
            <u>2.4 Restrictions and Conditions.</u>
          </strong>{' '}
          You shall not, directly, indirectly or through your Authorized Users,
          employees and/or the Software of independent contractors, permit
          anyone to:
          <ul>
            <li>
              attempt to sell, transfer, assign, rent, lend, lease, sublicense
              or otherwise provide third parties rights or access to the
              Software;
            </li>
            <li>
              &quot;frame,&quot; &quot;mirror,&quot; copy or otherwise enable
              third parties to use the Software (or any component thereof) as a
              software bureau or other outsourced software;
            </li>
            <li>
              allow access to the Software by multiple individuals impersonating
              a single end user;
            </li>
            <li>
              use the Software in a manner that interferes with, degrades, or
              disrupts the integrity or performance of any OODL Marketplace
              technologies, Software, systems or other offerings, including data
              transmission, storage and backup;
            </li>
            <li>
              reverse engineer, decompile, disassemble, or otherwise attempt to
              derive the source code of the Software, or use the Software for
              the purpose of developing a product or Software that competes
              with, or provides to third parties a product or Software similar
              to OODL Marketplaces online products and softwares;
            </li>
            <li>
              circumvent or disable any security features or functionality
              associated with Software; or
            </li>
            <li>use the Software in any manner prohibited by law.</li>
          </ul>
          <strong>
            <u>2.5 Reservation of Rights.</u>
          </strong>{' '}
          The Company, its suppliers, and its licensors reserve all rights not
          expressly granted to you. The Company retains all ownership and
          intellectual property rights to the Software and any changes,
          enhancements, or modifications incorporated into the Software,
          including those resulting from suggestions, requests, recommendations,
          or other feedback provided by you or your authorized users.
          <br />
          <br />
          <strong>
            <u>2.6 Return of Hosted Data.</u>
          </strong>{' '}
          If you request it within thirty (30) days of the expiration or
          termination of this Agreement, the Company shall make all your data
          stored within the Software available at the time of expiration or
          termination. Thirty (30) days after termination, the Company shall
          have no further obligation to you and may, at its option, permanently
          delete or destroy all your trades and data within the Software and all
          information and materials contained therein, as permitted by law.
          <br />
          <br />
          <strong>
            <u>2.7 Delivery of Software and Materials.</u>
          </strong>{' '}
          The Software, and any updates or maintenance releases thereof, shall
          be made available only on a hosted basis and will not be delivered to
          you in object code or physical media. The Software and any
          deliverables under this Agreement will be delivered only through an
          electronic data transfer.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          3. SOFTWARES.
        </Typography>
        <Typography component="p">
          <strong>
            <u>3.1. SOFTWARES.</u>
          </strong>{' '}
          ADDITIONAL SUPPORT SOFTWARE, INCLUDING CUSTOM CONFIGURATION,
          CONSULTING, REPORT DEVELOPMENT, TRAINING AND SYSTEM INTEGRATION, MAY
          BE SEPARATELY PURCHASED FROM THE COMPANY UNDER THE TERMS OF AN
          ADDENDUM TO THIS AGREEMENT. FOR CLARITY, THE COMPANY HAS NO OBLIGATION
          TO SUPPORT YOUR OWN TECHNOLOGY OR INTERNAL INFRASTRUCTURE, PROVIDE
          FREE TRAINING, OR PROVIDE CONSULTING ON YOUR CREATED CONTENT SUCH AS
          VIEWS, REPORTS, AND CONFIGURATIONS OR THIRD-PARTY TECHNOLOGIES AND
          SOFTWARE UNLESS AGREED TO IN WRITING VIA AN APPROVED SALES AGREEMENT
          AND OR STATEMENT OF WORK. SHOULD COMPANY, AT ITS DISCRETION, PROVIDE
          ONE OR ALL OF THESE SOFTWARE TO YOU AT ONE POINT IN TIME AS A
          COURTESY, THAT SHALL NOT CONSTITUTE WAIVER OF THIS PROVISION.
        </Typography>
        <Typography
          variant="h4"
          component="h4"
          py={3}
        >
          4. YOUR OBLIGATIONS.
        </Typography>
        <Typography component="p">
          <strong>
            <u>4.1 Fees and Payment Terms.</u>
          </strong>{' '}
          In consideration of the rights granted herein, you shall pay the
          Company the amounts specified on this site and therefore incorporated
          herein to the Agreement (“Fees”) applicable scope limitations set
          forth. You agree that the Company can withhold Fees for the full Term
          of the Agreement, which will be invoiced and/or settled by the Company
          on a per-trade basis.
          <ul>
            <li>
              Fees are profits exclusive of any applicable sales, use, import or
              export taxes, duties, fees, value-added taxes, tariffs or other
              amounts attributable to your execution of this Agreement or use of
              the Software (collectively, “Taxes”). You shall be solely
              responsible for paying any taxes. If the Company is required to
              pay Taxes on your behalf, you agree that the Company shall have
              the legal right to withhold and pay any government institutions as
              necessary and accordingly.
            </li>
            <li>
              All amounts shall be paid to the Company promptly upon the trade
              closure. An invoice shall be deemed undisputed if you fail to
              notify the Company in writing of any disputed amounts within
              thirty (30) days of receiving such invoice.
            </li>
            <li>
              Fees not paid when due shall be subject to a late fee equal to One
              and One-half per cent (1.5%) of the unpaid balance per month and
              not higher than the highest monthly rate permitted by applicable
              law. Company further reserves (among other rights and remedies)
              the right to suspend access to the Software. Amounts payable to
              the Company shall continue to accrue during any suspension period.
              They must be paid as a condition precedent to reactivation, which
              reactivation is at the sole discretion of the Company.
            </li>
            <li>
              All prices and other payment terms are confidential information of
              the Company and you agree not to disclose such information to any
              third party throughout the Term and for three (3) years
              thereafter.
            </li>
            <li>
              Except as otherwise specified in this Agreement, fees are based on
              trades and not actual usage, payment obligations are
              non-cancelable, fees paid are non-refundable, and the scope of the
              Software cannot be decreased during the relevant term.
            </li>
          </ul>
          <strong>
            <u>4.2 Compliance with Laws.</u>
          </strong>{' '}
          The Software is of multi-jurisdictional origin. You shall adhere to
          all applicable state, federal, local and international laws and
          treaties in all jurisdictions in which You use the Software, including
          all end-user, end-use and destination restrictions issued by U.S. and
          other governments and U.S. Export Administration Act and its
          associated regulations. You will not upload any data or information to
          the Software for which You do not have full and unrestricted rights.
          Notwithstanding anything to the contrary in this Agreement or any
          other agreement between the parties, You will not upload any data or
          information that is subject to government regulation, including,
          without limitation, protected health information regulated under the
          Health Insurance Portability and Accountability Act of 1996 or
          sensitive financial information regulated under the Gramm-Leach-Bliley
          Act of 1999.
          <br />
          <br />
          YOU EXPRESSLY AGREE NOT TO VIOLATE OFFICE OF FOREIGN ASSETS CONTROL
          (OFAC) SANCTIONS PROGRAM AS COVERED BY THE U.S. DEPARTMENT OF TREASURY
          INCLUDING EMBARGOES SANCTIONS (CRIMEA - REGION OF UKRAINE, CUBA, IRAN,
          NORTH KOREA, SUDAN, AND SYRIA) PROHIBIT THE USE AND ALL TRANSACTIONS
          (INCLUDING IMPORTS AND EXPORTS) WITHOUT A LICENSE AUTHORIZATION TO THE
          ABOVE LISTED COUNTRIES. SHOULD YOU BECOME AWARE OF A VIOLATION OR
          POTENTIAL VIOLATION, YOU WILL IMMEDIATELY NOTIFY THE COMPANY, AND YOU
          WILL COOPERATE WITH ALL GOVERNMENTAL INQUIRIES AND INVESTIGATIONS. YOU
          WILL ACTIVELY UPDATE THEIR CLIENT DATABASE AND MAINTAIN AN ONGOING
          LIST OF SANCTIONED NATIONS AND/OR ASSOCIATED BAD ACTORS AS DEFINED
          UNDER THE LAW.
          <br />
          <br />
          <strong>
            <u>4.3 Licensing and Compliance Obligations.</u>
          </strong>{' '}
          You represent and warrant that you are in good standing and will
          continue to remain in good standing throughout this Agreement with
          respective federal, state, and/or local regulators and with
          self-regulatory entities if required.
          <br />
          <br />
          <strong>
            <u>4.4 Privacy Obligations.</u>
          </strong>{' '}
          You represent and warrant that the Software provided herein is
          permitted under all federal and state laws and regulations governing
          your obligations to maintain the privacy of personally identifiable
          information (&quot;PII&quot;) and any and all policies, procedures,
          and contractual provisions you have implemented concerning the
          protection of PII.
          <br />
          <br />
          <strong>
            <u>4.5 Facilities.</u>
          </strong>{' '}
          You agree to maintain your own communications lines, Internet
          connections, hardware, software, equipment and related facilities
          (collectively “Facilities”) necessary and sufficient for using
          Software at your own expense. The company neither warrants nor
          guarantees that Software is compatible with Your existing or future
          Facilities.
        </Typography>
        <Typography
          variant="h4"
          component="h4"
          py={3}
        >
          5. TRADING SOFTWARES.
        </Typography>
        <Typography component="p">
          <strong>
            <u>5.1 Custody:</u>
          </strong>{' '}
          You agree to execute third-party agreements and any related documents,
          including but not limited to Sales Purchase Agreements, exhibits,
          attachments and/or addendums (“Sales Purchase Agreement”)
          <br />
          <br />
          <strong>
            <u>5.2 Legal Compliance.</u>
          </strong>{' '}
          Your license to trade through Company software is subject to and
          contingent upon You complying with all federal, state and
          self-regulatory laws and regulations. You represent and warrants that
          it has adopted written supervisory policies and procedures reasonably
          designed to prevent the violation of applicable laws and regulations.
          If the Company, in its sole determination, determines that further use
          of the Software violates applicable law, the Company, in its sole
          discretion, may immediately suspend or limit the nature of the
          Software being offered or immediately terminate this Agreement.
          <br />
          <br />
          <strong>
            <u>5.3 Trading Procedures and Agreements.</u>
          </strong>
          You agree to comply with all procedures and agreements of the company
          and any custodian in executing trades and depositing and withdrawing
          funds in fiat and/or digital assets. You agree to evaluate
          independently whether the Software offered through the Company
          satisfies your requirements. For clarity, You acknowledge and agree
          that the Company is not responsible for ensuring that the Software
          offered satisfies your requirements.
        </Typography>
        <Typography
          variant="h4"
          component="h4"
          py={3}
        >
          6. TERM AND TERMINATION.
        </Typography>
        <Typography component="p">
          <strong>
            <u>6.1 Term.</u>
          </strong>{' '}
          Unless otherwise specified, the initial term of this Agreement will
          begin on the Effective Date and remain effective until either party
          terminates.
          <br />
          <br />
          <strong>
            <u>6.2 Automatic Renewal.</u>
          </strong>{' '}
          Unless terminated, the Agreement shall remain under the same terms and
          conditions set forth herein, except that COMPANY shall have the right
          to modify the terms and conditions in writing thirty (30) days before
          the commencement of the automatic renewal date at the end of the
          Contract Term.
          <br />
          <br />
          <strong>
            <u>6.3 Termination.</u>
          </strong>
          Either party may terminate this Agreement if:{' '}
          <span className="consolas">(a)</span> the other party materially
          breaches this Agreement, and such breach has not been cured within
          thirty (30) days of providing notice thereof;{' '}
          <span className="consolas">(b)</span> at any time by COMPANY with
          thirty (30) days advance notice to the You; or{' '}
          <span className="consolas">(c)</span> at any time by the You with
          ninety (90) days advance notice to COMPANY. A material breach for You
          includes failure to maintain an effective agreement with COMPANY’s
          affiliated partners or subsidiary companies.
          <br />
          <br />
          <strong>
            <u>6.4 Effect of Termination.</u>
          </strong>
          Upon expiration or termination for any reason, You shall discontinue
          all use of the Software and return any and all documentation provided
          to you by the Company. Any trades made at or before termination will
          be executed, and you are responsible for them. You will be released
          from further obligations under this Agreement following the effective
          date of termination, except for its liability for any unpaid Fees for
          the use of the Software before the effective date of termination, and
          subject to the survival provisions of this Agreement.
        </Typography>
        <Typography
          variant="h4"
          component="h4"
          py={3}
        >
          7. INDEMNIFICATION.
        </Typography>
        <Typography component="p">
          <strong>
            <u>7.1 You.</u>
          </strong>{' '}
          You shall indemnify and hold the Company, its suppliers, licensors,
          agents, employees, directors, and shareholders harmless from and
          against any and all claims, costs, damages, losses, liabilities and
          expenses, including, but not limited to, reasonable attorneys&rsquo;
          fees and costs arising out of or in connection with a claim which, if
          true, would constitute a breach of any of Your obligations,
          representations and/or warranties under this Agreement. If the Company
          is required to seek legal remedies to enforce collection of any
          amounts due under this Agreement, You agree to reimburse for all
          additional costs associated with collection of that past due amount,
          including, but not limited to, reimbursement of collection and
          attorney&rsquo;s fees.
          <br />
          <br />
          <strong>
            <u>7.2 COMPANY.</u>
          </strong>{' '}
          <ul>
            <li>
              The Company shall indemnify and hold You harmless from and against
              any and all claims, costs, damages, losses, liabilities and
              expenses, including attorneys&rsquo; fees and costs arising out of
              a third-party claim that the Software infringes or misappropriates
              as of the Effective Date or any copyright or trade secret of any
              third party during the term of this Agreement. The Company shall
              have no indemnification obligation, and you shall indemnify the
              Company pursuant to this Agreement for claims of infringement
              arising from the combination of Software with any unique aspects
              of your business, for instance, your content, products, hardware
              or business processes, any change or modification by you to the
              Software, or for any use of the Software or any of the Company
              software not expressly authorized herein.
            </li>
            <li>
              The Company shall indemnify and hold you harmless from and against
              any and all claims, costs, damages, losses, liabilities and
              expenses, including attorneys’ fees and costs arising out of a
              third-party claim arising from or related to a trade error in
              which the Company is found by a fact finder or other arbitration
              or adjudicate forum to be One Hundred Percent (100%) at fault. The
              Company shall have no liability, and you shall indemnify the
              Company for any and all claims related to trade errors that are
              not One Hundred Percent (100%) the fault of the Company.
            </li>
          </ul>
          <strong>
            <u>7.3 Process.</u>
          </strong>{' '}
          A party seeking indemnification hereunder shall promptly notify in
          writing the other party of any claim for which defense and
          indemnification are sought. Each party agrees that it will not,
          without the other’s prior written consent, enter into any settlement
          or compromise of any claim that
          <ul>
            <li>
              results, or creates a likelihood of a result, that in any way
              diminishes or impairs any right or defense that would otherwise
              exist absent such settlement or compromise; or
            </li>
            <li>
              constitutes or includes an admission of liability, fault,
              negligence or wrongdoing on the part of the other party. Each
              indemnifying party has the sole right to control the defense of
              any claim for which it provides indemnification with counsel
              mutually acceptable to the parties. At its own expense, the
              indemnified party may participate in the defense of any such
              claim.
            </li>
          </ul>
        </Typography>
        <Typography
          variant="h4"
          component="h4"
          py={3}
        >
          8. WARRANTY/ LIABILITY/ TOTAL LIABILITY.
        </Typography>
        <Typography component="p">
          <strong>
            <u>8.1 Mutual Warranties.</u>
          </strong>{' '}
          Each party represents and warrants to the other that it is duly
          authorized to execute this Agreement and perform the obligations set
          forth herein.
          <br />
          <br />
          <strong>
            <u>8.2 Interpretation.</u>
          </strong>{' '}
          The limitations in sections 8.3 and 8.4 are independent of each other.
          The limitation of damages outlined in section 8.3 shall survive any
          failure of the essential purpose of the limited remedy in section 8.4.
          DISCLAIMER.
          <br />
          <br />
          THE SOFTWARE, INSTRUCTION AND SUPPORT OR OTHER SOFTWARES PROVIDED IN
          CONNECTION WITH THIS AGREEMENT (COLLECTIVELY, &quot;SOFTWARES&quot;),
          INCLUDING BUT NOT LIMITED TO, THE SOFTWARE, ASSISTANCE, CONSULTATIONS,
          RECOMMENDATIONS, OR OTHERWISE PROVIDED BY EMPLOYEES, AGENTS, DIRECTORS
          OR OTHERWISE OF COMPANY ARE PROVIDED STRICTLY ON AN &quot;AS IS&quot;
          BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER
          EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
          LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, TITLE, FITNESS
          FOR A PARTICULAR PURPOSE, OR SATISFACTORY RESULTS ARE HEREBY
          DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY
          COMPANY, ITS SUPPLIERS AND ITS LICENSORS. THE COMPANY WARRANTS ONLY
          THAT THE SOFTWARE WILL PERFORM SUBSTANTIALLY IN ACCORDANCE WITH ITS
          DOCUMENTATION. THE COMPANY DOES NOT WARRANT THAT THE SOFTWARE OR
          SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE AND DISCLAIMS ANY
          WARRANTY OR REPRESENTATION REGARDING AVAILABILITY OF THE SOFTWARE,
          SOFTWARE, SOFTWARE LEVELS OR PERFORMANCE, OR THE USE OF COMPANY
          ANALYTICS.
          <br />
          <br />
          <strong>
            <u>8.3 Electronic Limitations.</u>
          </strong>{' '}
          You acknowledge and agree that Software may be subject to
          interruption, limitations, delays, and other problems inherent in
          Internet applications and electronic communications. The Company is
          not responsible for any such delays, delivery failures, or any other
          damage resulting from events beyond the Company’s reasonable control,
          without regard to whether such events are reasonably foreseeable by
          the Company.
          <br />
          <br />
          <strong>
            <u>8.4 LIMITATIONS.</u>
          </strong>{' '}
          YOUR EXCLUSIVE REMEDY AND COMPANY’S, ITS SUPPLIERS’ AND LICENSORS’
          TOTAL AGGREGATE LIABILITY RELATING TO, ARISING OUT OF, IN CONNECTION
          WITH, OR INCIDENTAL TO THIS AGREEMENT, WHETHER FOR BREACH OF CONTRACT,
          BREACH OF WARRANTY, INDEMNIFICATION OR ANY OTHER CLAIM SHALL BE
          LIMITED TO THE ACTUAL DIRECT DAMAGES INCURRED BY YOU, UP TO THE
          AGGREGATE AMOUNTS PAID IN FEES BY YOU AND RECEIVED BY COMPANY
          HEREUNDER DURING THE TWELVE MONTHS IMMEDIATELY PRECEDING THE
          APPLICABLE CLAIM. THE EXISTENCE OF MULTIPLE CLAIMS OR SUITS UNDER OR
          RELATED TO THIS AGREEMENT WILL NOT ENLARGE OR EXTEND THIS LIMITATION
          OF DAMAGES. YOU HEREBY RELEASES THE COMPANY, ITS SUPPLIERS AND
          LICENSORS FROM ALL OBLIGATIONS, LIABILITY, CLAIMS OR DEMANDS IN EXCESS
          OF THIS LIMITATION. THE PROVISIONS OF THIS SECTION DO NOT WAIVE OR
          LIMIT THE COMPANY’S ABILITY TO OBTAIN INJUNCTIVE OR OTHER LEGAL OR
          EQUITABLE RELIEF FOR BREACH OF THIS AGREEMENT.
          <br />
          <br />
          <strong>
            <u>
              8.5 EXCLUSION OF CERTAIN DAMAGES AND LIMITATIONS OF TYPES OF
              LIABILITY.
            </u>
          </strong>{' '}
          IN NO EVENT WILL THE COMPANY BE LIABLE FOR ANY SPECIAL, CONSEQUENTIAL,
          INCIDENTAL, INDIRECT OR PUNITIVE DAMAGES, LOST PROFITS OR LOST REVENUE
          ARISING OUT OF OR RELATED TO THE SUBJECT MATTER OF THIS AGREEMENT OR
          THE USE OF OR INABILITY TO USE THE SOFTWARE. THE FOREGOING EXCLUSION
          AND LIABILITY LIMITATIONS APPLY EVEN IF SUCH PARTY HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES AND EVEN IN THE EVENT OF STRICT OR
          PRODUCT LIABILITY.
          <br />
          <br />
          <strong>
            <u>8.6 Force Majeure.</u>
          </strong>{' '}
          The Company shall not be liable for any force majeure or cause beyond
          the Company’s reasonable control, including but not limited to, Act of
          God, fire, pandemic, government actions or failure to act, labor
          dispute, cyber- attack, third-party fraud, unforeseeable computer or
          software system outage, or similar interruption in Software that is
          not within the reasonable control of COMPANY.
          <br />
          <br />
          <strong>
            <u>8.7 NO THIRD-PARTY LIABILITY.</u>
          </strong>{' '}
          NOTHING EXPRESSED OR REFERRED TO IN THIS AGREEMENT WILL BE CONSTRUED
          TO GIVE ANY PERSON OTHER THAN THE PARTIES TO THIS AGREEMENT ANY LEGAL
          OR EQUITABLE RIGHT, REMEDY, OR CLAIM UNDER OR WITH RESPECT TO THIS
          AGREEMENT OR ANY PROVISION OF THIS AGREEMENT. THIS AGREEMENT AND ALL
          OF ITS PROVISIONS AND CONDITIONS ARE FOR THE SOLE AND EXCLUSIVE
          BENEFIT OF THE PARTIES TO THIS AGREEMENT AND THEIR SUCCESSORS AND
          ASSIGNS.
          <br />
          <br />
          <strong>
            <u>8.8 Independent Contractor Relationship.</u>
          </strong>{' '}
          No joint venture, partnership, employment, agency or exclusive
          relationship exists between the parties as a result of this Agreement
          or use of the Software. Neither party shall have the express or
          implied authority to bind the other to any obligation to any third
          party, other than as expressly stated in this Agreement.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          9. NOTICES AND REQUESTS.
        </Typography>
        <Typography component="p">
          <strong>
            <u>9.1 NOTICES AND REQUESTS.</u>
          </strong>{' '}
          EITHER PARTY MAY GIVE NOTICE TO THE OTHER PARTY BY MEANS OF ELECTRONIC
          MAIL TO THE PRIMARY CONTACT DESIGNATED ON THE ORDER OR BY WRITTEN
          COMMUNICATION SENT BY FIRST CLASS MAIL OR PRE-PAID POST, EITHER OF
          WHICH SHALL CONSTITUTE WRITTEN NOTICE UNDER THIS AGREEMENT. IN THE
          EVENT YOU DESIRE TO INCREASE THE NUMBER OF TRADES PERMITTED TO USE THE
          SOFTWARE DURING THE TERM, YOU MAY PURCHASE SUCH RIGHTS VIA TELEPHONE,
          FACSIMILE OR E-MAIL. AN E-MAIL OR OTHER WRITING FROM THE COMPANY
          CONFIRMING SUCH ORDER SHALL BE DEEMED SUFFICIENT TO MODIFY THE
          QUANTITY OF AUTHORIZED TRADES SET FORTH. ALL ADDITIONAL TRADES BY YOU
          DURING THE TERM SHALL BE SUBJECT TO THE TERMS OF THIS AGREEMENT. FOR
          CLARITY, IN NO EVENT SHALL ANY OTHER TERM OR PROVISION OF THIS
          AGREEMENT BE DEEMED MODIFIED, AMENDED OR ALTERED AS A RESULT OF SUCH
          INCREASED TRADES, AND ALL OTHER CHANGES TO THIS AGREEMENT SHALL BE
          GOVERNED BY TERMS OF SECTION 10 BELOW.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          10. ADDITIONAL TERMS.
        </Typography>
        <Typography component="p">
          <strong>
            <u>10.1 ADDITIONAL TERMS.</u>
          </strong>{' '}
          WITH THE EXCEPTION OF ADDITIONAL TRADES OBTAINED BY YOU UNDER SECTION
          9, THE COMPANY SHALL NOT BE BOUND BY ANY SUBSEQUENT TERMS, CONDITIONS
          OR OTHER OBLIGATIONS INCLUDED IN ANY YOU PURCHASE ORDER, RECEIPT,
          ACCEPTANCE, CONFIRMATION OR OTHER CORRESPONDENCE FROM YOU UNLESS
          EXPRESSLY ASSENTED TO IN WRITING BY COMPANY AND COUNTER- SIGNED BY ITS
          AUTHORIZED AGENT. THE PARTIES MAY SUPPLEMENT THE TERMS OF THIS
          AGREEMENT AT ANY TIME BY SIGNING A WRITTEN ADDENDUM, WHICH SHALL BE
          DEEMED INCORPORATED BY THIS REFERENCE UPON EXECUTION. THE TERMS OF ANY
          ADDENDUM SHALL CONTROL ANY CONFLICTING TERMS IN THIS AGREEMENT. UNLESS
          EXPRESSLY STATED OTHERWISE IN AN APPLICABLE ADDENDUM, ALL ADDENDA
          SHALL TERMINATE UPON THE EXPIRATION OR TERMINATION OF THIS AGREEMENT.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          11. CONFIDENTIALITY.
        </Typography>
        <Typography component="p">
          <strong>
            <u>11.1 Obligations.</u>
          </strong>{' '}
          Each of the parties agrees: (i) not to intentionally disclose any of
          the other party’s Confidential Information to any third parties except
          as mandated by law and except to those subcontractors hereunder who
          agree to be bound by confidentiality obligations no less stringent
          than those outlined in this Agreement; (ii) not to use any of the
          other party’s Confidential Information for any purposes except
          carrying out such party’s rights and responsibilities under this
          Agreement; and (iii) to keep the other party’s Confidential
          Information confidential using the same degree of care such party uses
          to protect its own confidential information; provided, however, that
          such party shall use at least reasonable care. These obligations shall
          survive termination of this Agreement.
          <br />
          <br />
          <strong>
            <u>11.2 Definition.</u>
          </strong>{' '}
          For the purpose of this Agreement, “Confidential Information” means
          non-public information disclosed by either party to the other party,
          either directly or indirectly, in writing, orally or by inspection of
          tangible objects, or to which the other party may have access, or any
          other information which a reasonable person would consider
          confidential and/or which is marked “confidential” or “proprietary” or
          some similar designation by the disclosing party or which is of a
          confidential nature even though not specifically so designated.
          Confidential Information shall not, however, include any information
          which the recipient can establish:{' '}
          <span className="consolas">(i)</span> was or has become generally
          known or available or in the public domain without direct or indirect
          fault, action, or omission of the recipient;{' '}
          <span className="consolas">(ii)</span> was known by the recipient
          before the time of disclosure, according to the recipient’s prior
          written documentation; <span className="consolas">(iii)</span> was
          received by the recipient from a source other than the discloser,
          rightfully having possession of and the right to disclose such
          information; or <span className="consolas">(iv)</span> was
          independently developed by the recipient, where such independent
          development has been documented by the recipient.
          <br />
          <br />
          <strong>
            <u>11.3 Exception for Aggregated Information.</u>
          </strong>{' '}
          Notwithstanding the foregoing provisions of Section 10, nothing
          expressed or referred to in this Agreement shall preclude the Company
          from using, disclosing, and selling information obtained by
          aggregating data provided by You and captured by the Software.
          Aggregated Information means usage data and/or all other information
          about multiple trades or that does not contain Personally Identifiable
          Information (“PII”). PII means any informational data that could
          potentially be used to identify a particular person, you or another
          user.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          12. MISCELLANEOUS.
        </Typography>
        <Typography component="p">
          <strong>
            <u>12.1 GOVERNING LAW.</u>
          </strong>{' '}
          THIS AGREEMENT SHALL BE GOVERNED BY DELAWARE LAW AND CONTROLLING
          UNITED STATES FEDERAL LAW, WITHOUT REGARD TO THE CHOICE OR CONFLICTS
          OF LAW PROVISIONS OF ANY JURISDICTION TO THE CONTRARY, AND ANY
          DISPUTES, ACTIONS, CLAIMS OR CAUSES OF ACTION ARISING OUT OF OR IN
          CONNECTION WITH THIS AGREEMENT OR THE SOFTWARE SHALL BE SUBJECT TO THE
          EXCLUSIVE JURISDICTION OF THE STATE AND FEDERAL COURTS LOCATED IN
          DELAWARE.
          <br />
          <br />
          <strong>
            <u>12.2 ARBITRATION.</u>
          </strong>{' '}
          THE PARTIES HERETO AGREE THAT ALL DISPUTES, ACTIONS, CLAIMS, CAUSES OF
          ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE SOFTWARE
          SHALL BE SUBJECT TO MANDATORY, BINDING ARBITRATION GOVERNED BY THE
          RULES OF THE AMERICAN ARBITRATION ASSOCIATION “AAA”. SUCH ARBITRATION
          SHALL BE CONDUCTED BY AN ARBITRATOR IN DELAWARE, AND THE FINAL ORDER
          OF THE ARBITRATOR(S) SHALL BE FINAL AND BINDING ON ALL PARTIES.
          <br />
          <br />
          <strong>
            <u>12.3 NO WAIVER.</u>
          </strong>{' '}
          THE FAILURE OF THE COMPANY TO ENFORCE ANY RIGHT OR PROVISION IN THIS
          AGREEMENT SHALL NOT CONSTITUTE A WAIVER OF SUCH RIGHT OR PROVISION.
          <br />
          <br />
          <strong>
            <u>12.4 SURVIVAL.</u>
          </strong>{' '}
          ALL DISCLAIMERS, LIMITATIONS, PAYMENT OBLIGATIONS, CONFIDENTIALITY,
          GOVERNING LAW, ARBITRATION, WAIVER AND RESTRICTIONS OF WARRANTY SHALL
          SURVIVE TERMINATION OF THIS AGREEMENT, AS WELL AS THE PROVISIONS OF
          THIS SECTION 12 SHALL SURVIVE TERMINATION OF THIS AGREEMENT.
          <br />
          <br />
          <strong>
            <u>12.5 SEVERABILITY.</u>
          </strong>{' '}
          IF ANY PART OF THIS AGREEMENT IS FOUND TO BE ILLEGAL, UNENFORCEABLE,
          OR INVALID, YOU’S RIGHT TO USE THE SOFTWARE WILL IMMEDIATELY
          TERMINATE, EXCEPT FOR THOSE PROVISIONS NOTED ABOVE WHICH WILL CONTINUE
          IN FULL FORCE AND EFFECT.
          <br />
          <br />
          <strong>
            <u>12.6 ASSIGNMENT.</u>
          </strong>{' '}
          YOU SHALL NOT WITHOUT THE PRIOR WRITTEN CONSENT OF COMPANY ASSIGN,
          TRANSFER, OR OTHERWISE DELEGATE, IN WHOLE OR IN PART, THE BENEFIT OF,
          OR YOU’S RIGHTS OR OBLIGATIONS UNDER THE AGREEMENT. NOR SHALL YOU HAVE
          THE RIGHT TO ASSIGN THE AGREEMENT, INCLUDING BY OPERATION OF LAW, TO
          THE SURVIVING PARTY OF ANY MERGER, ACQUISITION, OR REORGANIZATION TO
          WHICH IT IS A PARTY, OR TO THE PURCHASER OF ALL OR SUBSTANTIALLY ALL
          OF ITS ASSETS.
          <br />
          <br />
          <strong>
            <u>12.7 AUTHORITY.</u>
          </strong>{' '}
          YOU HAVE THE AUTHORITY TO ENTER INTO THIS AGREEMENT FOR GOOD AND
          VALUABLE CONSIDERATION UNDER THE LAW.
          <br />
          <br />
          <strong>
            <u>12.8 COMPLETE AGREEMENT.</u>
          </strong>{' '}
          THIS AGREEMENT, TOGETHER WITH ANY EXHIBITS, COMPRISES THE ENTIRE
          AGREEMENT BETWEEN YOU AND COMPANY AND SUPERSEDES ALL PRIOR OR
          CONTEMPORANEOUS NEGOTIATIONS, DISCUSSIONS OR AGREEMENTS, WHETHER
          WRITTEN OR ORAL, BETWEEN THE PARTIES REGARDING THE SUBJECT MATTER
          CONTAINED HEREIN:
        </Typography>
        <List
          dense={true}
          sx={{ listStyleType: 'disc', pl: 2 }}
        >
          <ListItem sx={{ display: 'list-item' }}>
            When you leave a comment, you may opt-in to saving your name, email
            address, and website in cookies for convenience. These cookies will
            last for 30 days.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Temporary cookies are set on the login page to determine cookie
            acceptance, discarded on browser closure.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Login cookies last for two days, and screen options cookies last for
            a year. Selecting &quot;Remember Me&quot; persists login for 30
            days.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Editing or publishing an article sets an additional cookie, expiring
            after 1 day.
          </ListItem>
        </List>
        <Typography
          variant="h3"
          component="h3"
          mt={6}
          pt={3}
          fontWeight={700}
        >
          Privacy Policy
        </Typography>
        <Typography
          component="p"
          pt={1}
          color={colors['gray.400']}
        >
          Last updated May 1, 2024
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          pb={3}
          pt={6}
        >
          ACCEPTANCE POLICY
        </Typography>
        <Typography component="p">
          This Company, (“OMP”, “we”, “us” or “our”) Privacy Policy covers our
          treatment of personal or personally identifiable information
          (&quot;Personal Information&quot;), that may be collected about users
          and visitors of our site (“Users” or “you,” or “your”) when you are on
          our website and when you use our website (“Website”) or mobile
          application (“Mobile App”), collectively referred to as the
          “Software”. Except where stated otherwise, this policy does not apply
          to the practices of companies that we do not own or control or to
          individuals that are not under our supervisory control.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          AGE RESTRICTIONS
        </Typography>
        <Typography component="p">
          Use of the Software by anyone under 18 is expressly prohibited.
          Persons under 18 are not allowed to register with or in any way, use
          the Software. We do not knowingly collect Personal Information from or
          about anyone under the age of 18. If we discover that we have
          collected Personal Information from someone under 18, we will delete
          that information immediately. Please refer to our End User License
          Agreement and Terms of Software (License) for additional age
          verification and eligibility information.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          COLLECTION OF YOUR PERSONAL INFORMATION
        </Typography>
        <Typography component="p">
          We will collect Personal Information when you use the Software on our
          website or mobile application or when you visit cookie-enabled web
          pages. We may also log your IP address when you use our website or
          mobile application.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          USER ACCOUNT INFORMATION
        </Typography>
        <Typography component="p">
          We allow users to set up trade accounts or participate in our online
          communities. Under no condition will we share your user account
          information with another individual, business or government entity
          unless you have or wish to enter a trade with this party or we are
          required and compelled by law, court order, subpoena or other legal
          process. Personal Information may include but is not limited to, and
          where applicable, your:
          <ul>
            <li>Email and Login Credentials</li>
            <li>Name Date of Birth, Country of Origin, Gender</li>
            <li>Home or Delivery Phone Number, Address, and Zip Code</li>
            <li>Billing Address and Phone Number</li>
            <li>
              Institutional Information and identification of beneficial
              ownership
            </li>
            <li>
              Passport or National ID data, employment, and office location
              information
            </li>
            <li>Financial information</li>
            <li>
              Personal characteristics, including photographic image,
              handwriting or other unique biometric data.
            </li>
          </ul>
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          DEVICE-SPECIFIC INFORMATION
        </Typography>
        <Typography component="p">
          When you register or use the App, we collect device type within the
          mobile application and store this information in log files. We use
          this information to provide you with the most up-to-date applications
          and features or to advertise or promote products and software that may
          apply to your device type, as described in this Privacy Policy.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          TRACKING INFORMATION
        </Typography>
        <Typography component="p">
          Tracking information is collected and stored in log files as you
          navigate through our website or use the Software, including, but not
          limited to, trade history, delivery locations history, and goods
          tracking.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          GEOLOCATION DATA
        </Typography>
        <Typography component="p">
          If you use the Software through your mobile device, where applicable,
          we will collect your geo-location information to determine your
          location for fulfillment and compliance. You may no longer allow the
          App to use your location by turning this off at the device level.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          REFERRAL INFORMATION
        </Typography>
        <Typography component="p">
          If you invite others to use our website through invites and referrals,
          we will collect and retain the name and email address of the person
          receiving the invite to track referrals.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          COOKIES
        </Typography>
        <Typography component="p">
          To help us serve your needs better, we use “cookies” to store and
          track user information. A cookie is a small amount of data that is
          sent to your browser from a web server and stored on your computer’s
          hard drive. A website can use cookies to recognise repeat users or
          track web usage behavior. Cookies take up minimal room on your
          computer and cannot damage your computer’s files. Cookies assign a
          number to the user without meaning outside of the assigning website.
          Non-personal information and data may be automatically collected by
          virtue of the standard operation of our computer servers or by using
          cookies. If you do not want information collected through cookies,
          your browser allows you to deny or accept cookies. There may, however,
          be some features of the Software which require the use of cookies to
          customise the delivery of information to you. We do not control the
          use of third-party cookies (or any resulting information collected),
          and their use is not covered by our Privacy Policy. We do not have
          access to or control over these cookies.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          USE OF YOUR PERSONAL INFORMATION
        </Typography>
        <Typography component="p">
          To facilitate our operations, we may transfer, store, and process your
          information within our family of companies, partners, external
          Software providers based throughout the world. We contractually
          obligate recipients of your personal information to agree to at least
          the same level of privacy safeguards as required under applicable data
          protection laws. By communicating electronically with us, you
          acknowledge and agree to your personal information being processed in
          this way.
          <br />
          <br />
          <b>
            We use Personal Information collected pursuant to this Privacy
            Policy to:
          </b>
          <br />
          <br />
        </Typography>
        <Typography
          component="p"
          pl={3}
        >
          <strong>
            <u>PROVIDE THE SOFTWARE.</u>
          </strong>{' '}
          We use both personal and business Information, and any other
          information we collect from you to provide you with the Software, to
          support and enhance your use of the Software, to monitor our most
          commonly used features, to display your trade history, to display
          current trades, to allow you to rate trades, and to determine which
          trade features we need more of our focus or improvement.
          <br />
          <br />
          <strong>
            <u>EXPAND TO NEW MARKETS.</u>
          </strong>{' '}
          If the Software is not available in your geographic location, you will
          be presented with the option to provide us with your email address and
          zip code so that we may notify you when it becomes available.
          <br />
          <br />
          <strong>
            <u>FULFILLMENT OPTIMIZATION.</u>
          </strong>{' '}
          We use Personal Information and any other information we collect from
          you to determine where to offer or focus the Software, features,
          and/or resources.
          <br />
          <br />
          <strong>
            <u>PROMOTIONAL MATERIAL.</u>
          </strong>{' '}
          We may also track your past trades to provide you with a personalised
          profile of your trade history. In addition, we may notify you about
          new software or other information.
          <br />
          <br />
          <strong>
            <u>APPLICATION OPTIMIZATION.</u>
          </strong>{' '}
          We use Device-Specific Information to provide the correct application
          version depending on your device type and troubleshoot technical
          issues.
          <br />
          <br />
          <strong>
            <u>DIAGNOSE TECHNICAL PROBLEM.</u>
          </strong>{' '}
          We use your Internet Protocol (IP) address to help diagnose problems
          with our servers and to administer the Website and mobile application.
          Your IP address is used to gather broad demographic data. Your IP
          address contains no personal information about you.
          <br />
          <br />
          <strong>
            <u>SOFTWARE-RELATED ANNOUNCEMENTS.</u>
          </strong>{' '}
          We will send you strictly Software-related announcements on rare
          occasions when necessary. For instance, if our Software is suspended
          for maintenance, we might email you. As these are Software-related
          announcements and not promotional material, you may not opt out of
          these notices.
          <br />
          <br />
          <strong>
            <u>YOUR REGISTRATION.</u>
          </strong>{' '}
          We will send you a welcoming email to the address you provided at
          registration to verify your username and password. We will also
          communicate with you in response to any inquiries, Software requests,
          and any requests or inquiries regarding your account.
          <br />
          <br />
          <strong>
            <u>IDENTIFICATION OF AGE-RESTRICTED ACCOUNTS.</u>
          </strong>{' '}
          We use Personal Information to confirm that all Software users are at
          least 18. Any age-related issues that may arise will result in a flag
          and/or suspension of your account in our system. Repeated violations
          of the age restrictions in this Privacy Policy will result in
          disclosure to applicable authorities.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          USE OF NON-PERSONAL INFORMATION
        </Typography>
        <Typography component="h4">
          We use non-personal Information collected pursuant to this Privacy
          Policy for the purposes stated herein. As used herein, non-personal
          information may correspond to a particular person, account, or
          profile. We may also use or share this information with third parties
          for research, marketing, and other business purposes.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          TARGETED OR BEHAVIORAL ADVERTISING
        </Typography>
        <Typography component="h4">
          Targeted advertising (also known as Behavioral Advertising) uses
          information collected on an individual’s web browsing behaviour, such
          as the pages they have visited, the searches made, the type of device
          used, or orders placed. This information is then used to select which
          advertisement should be displayed to a particular individual via the
          Software. For example, if you have shown a preference for a product
          type while using the Software, you may be served an advertisement via
          the Software relating to that type of product. We may share or sell
          aggregated demographic information about our user base, including
          information related to your use of the Software (e.g., how you
          interact with our App and website, our partners and advertisers), your
          device type, UUID, purchase history, delivery location history, and
          click tracking, with third-party advertisers for the purpose of
          selecting an appropriate audience for their advertisements, but only
          after either removing from such information anything that personally
          identifies you or combining it with other information so that it no
          longer personally identifies you. This information does not identify
          individual users by name, email or other contact information.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          FACILITATE GENERAL IMPROVEMENTS TO THE SOFTWARE, WEBSITE OR MOBILE APP
        </Typography>
        <Typography component="h4">
          We strive to provide you with the best possible experience when using
          the Software, whether on the Website or mobile app. To that end, we
          use non-personal Information to assess the functionality of our
          Software and identify areas requiring additional resources or focus.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          DISCLOSURE OF YOUR INFORMATION
        </Typography>
        <Typography component="h4">
          We share collected Personal Information, or non-personal Information,
          from or about you only as set forth herein:
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          AGGREGATED DATA
        </Typography>
        <Typography component="h4">
          We may share or sell aggregated demographic or other information about
          our user base that includes non-personal Information and log data
          (including how users interact with our Mobile App and Website) with
          third parties for trade analysis, profiling, and to deliver targeted
          information about trades.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          THIRD-PARTY SOFTWARE PROVIDERS
        </Typography>
        <Typography component="h4">
          We use a third-party provider to host the ‘support’ section of the
          Software. Information collected within this section of the Software is
          governed by this Privacy Policy. Our third-party Software provider
          does not have access to this information. We may employ, at our
          discretion and without notice, third-party companies and individuals
          to facilitate our Software, to provide the Software on our behalf,
          provide You support, perform Website-related software (e.g., without
          limitation, maintenance Softwares, database management, web analytics
          and improvement of the Website’s features) or to assist us in
          analyzing how our Website and Software are used. Any access by these
          third parties to access to your Personal Information is provided only
          to perform these tasks on our behalf and is not retained by these
          third parties.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          PAYMENT/CREDIT CARD TRANSACTIONS
        </Typography>
        <Typography component="h4">
          All payments are processed by secure third-party payment processors
          and banks. We have no control over third-party payment processors or
          banks. Any information you provide to a third-party payment processor
          or bank is governed by such and is their separate privacy policy
          instead of this Privacy Policy. Please consult the privacy policy of
          the applicable third-party payment processor or bank if you wish to
          have details.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          LEGAL PROCESS
        </Typography>
        <Typography component="h4">
          We cooperate with federal, state, city, and local governments, law
          enforcement agencies and officials, and, whereby law and or court
          order, private parties to enforce and comply with any and all
          applicable laws related to or arising from the use of the Software. We
          will disclose any information about you to government or law
          enforcement officials or private parties as we, in our sole
          discretion, believe necessary or appropriate to respond to claims and
          legal processes (including but not limited to subpoenas), to protect
          the property and rights of our or a third party whose property or
          rights we are required to protect, as well as to protect the safety of
          the public or any person(s), or to prevent or stop activity we may
          consider to be illegal, unethical or that would otherwise expose us to
          legal action.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          ACQUISITION OF COMPANY
        </Typography>
        <Typography component="h4">
          If all or a substantial portion of the assets, business or stock of
          our company is acquired by, merged with or transferred to another
          party, or in the event that we go out of business or enter bankruptcy,
          your Personal Information would be one of the assets that is
          transferred to or acquired by a third party. You acknowledge that such
          transfers may occur and that any acquirer of us or our assets may
          continue to use your Personal Information pursuant to and as set forth
          in this Privacy Policy.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          DATA TRANSFERRED OUT OF THE EU
        </Typography>
        <Typography component="h4">
          We rely primarily on Model Contractual Clauses approved by the
          European Commission to facilitate the international transfer of
          personal information collected in the EEA, the United Kingdom and
          Switzerland (“European Personal Information”), and any onward transfer
          of such information to the extent the recipients of the European
          Personal Information may be in a country that the EU considers not to
          provide an adequate level of data protection. This includes transfers
          from our EU-based operating entities to our US-based operating entity.
          We may also rely on an adequacy decision of the European Commission
          confirming an adequate level of data protection in the jurisdiction of
          the party receiving the information. We are responsible for processing
          personal information we receive and subsequently transfer to a third
          party acting as an agent on behalf. Before we share your information
          with any third party, we will enter into a written agreement that the
          third party provides at least the same level of protection for the
          personal information as required under applicable data protection
          laws.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          UPDATING, CORRECTING OR DELETING YOUR PERSONAL INFORMATION
        </Typography>
        <Typography component="p">
          If you wish to update your Personal Information or email preferences
          or no longer wish to use our Software, you may correct, delete, or
          amend your personal information and preferences by making the change
          on our user information page or by emailing us at
          info@oodlemarket.com. We will respond to your request as soon as
          possible, usually within 30 days. We will take commercially reasonable
          steps to implement your opt-out requests promptly, but you may still
          receive communications from us for up to 30 days as we process your
          request.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          RETENTION OF PERSONAL INFORMATION
        </Typography>
        <Typography component="p">
          We will retain your Personal Information for the period necessary to
          fulfil the purposes outlined in this Privacy Policy, but in any event,
          for no less than the minimum period required by law and for no longer
          the maximum period allowed by law.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          SECURITY
        </Typography>
        <Typography component="p">
          All information we collect is securely stored within our database, and
          we use standard, industry-wide, Payment Card Industry-compliant,
          commercially reasonable security practices. However, as effective as
          encryption technology is, no security system is impenetrable. We
          cannot guarantee the security of our database, nor can we guarantee
          that the information you supply won’t be intercepted while being
          transmitted to us over the Internet, and any information you transmit
          to us, you do at your own risk. We recommend that you use unique
          numbers, letters and special characters in your password and not
          disclose your password to anyone. If you do share your password or
          personal information with others, you are responsible for all actions
          taken in the name of your account. If your password has been
          compromised for any reason, you should immediately notify us and
          change your password.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          PUBLIC FORUMS
        </Typography>
        <Typography component="p">
          Our Website and Mobile App may offer publicly accessible blogs or
          community forums. Any information you provide in these areas may be
          read, collected, and used for any purpose by others who access them,
          including but not limited to us. We are not responsible for the
          personally identifiable or other information you choose to submit in
          these areas. To request the removal of your personal information from
          our blogs or community forums, contact us at info@oodlemarket.com.
          Please be aware that we may not always be able to remove your Personal
          Information once it is posted in a public forum, so be very
          considerate before doing so.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          AMENDMENTS AND ALT 5 SIGMA CONTACT
        </Typography>
        <Typography component="p">
          Amendments. We reserve the right to amend this policy at any time. We
          will contact registered users by e-mail, and shall also post a notice
          of changes on our website, when and if the terms of this Privacy
          Policy are amended.
        </Typography>
        <Typography
          variant="h3"
          component="h3"
          mt={6}
          pt={3}
          fontWeight={700}
        >
          “END-USER TERMS OF USE” AND “END-USER AGREEMENT”
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          Access to the Site
        </Typography>
        <Typography component="p">
          <strong>Subject to these Terms.</strong> Company grants you a
          non-transferable, non-exclusive, revocable, limited license to access
          the Site solely for your own commercial use.
          <br />
          <br />
          <strong>Certain Restrictions.</strong> The rights approved to you in
          these Terms are subject to the following restrictions:{' '}
          <span className="consolas">(a)</span> you shall not sell, rent, lease,
          transfer, assign, distribute, host, or otherwise commercially exploit
          the Site; <span className="consolas">(b)</span> you shall not change,
          make derivative works of, disassemble, reverse compile or reverse
          engineer any part of the Site; <span className="consolas">(c)</span>{' '}
          you shall not access the Site to build a similar or competitive
          website; and <span className="consolas">(d)</span> except as expressly
          stated herein, no part of the Site may be copied, reproduced,
          distributed, republished, downloaded, displayed, posted or transmitted
          in any form or by any means unless otherwise indicated, any future
          release, update, or other addition to functionality of the Site shall
          be subject to these Terms. All copyright and other proprietary notices
          on the Site must be retained on all copies.
          <br />
          <br />
          The Company reserves the right to change, suspend, or cease the Site
          with or without notice to you. You agree that the Company will not be
          held liable to you or any third party for any change, interruption, or
          termination of the Site or any part.
          <br />
          <br />
          <strong>No Support or Maintenance.</strong> You agree that the Company
          is not obligated to provide you with any support in connection with
          the Site.
          <br />
          <br />
          Excluding any user content you may provide, you are aware that all the
          intellectual property rights in the Software on the site and its
          content are owned by the Company or the Company’s suppliers, including
          copyrights, patents, trademarks, and trade secrets. Note that these
          Terms and access to the Software and site do not give you any rights,
          title or interest in or to any intellectual property rights, except
          for the limited access rights expressed in this Agreement. The Company
          and its suppliers reserve all rights not granted.
          <br />
          <br />
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          Third-Party Links & Ads; Other Users
        </Typography>
        <Typography component="p">
          <strong>Third-Party Links & Ads.</strong> The Software and the site
          may contain links to third-party websites and software and/or display
          advertisements for third parties. Such Third-Party Links & Ads are not
          under the Company’s control, and the Company is not responsible for
          any Third-Party Links & Ads. The company provides access to these
          Third-Party Links & Ads only as a convenience to you. It does not
          review, approve, monitor, endorse, warrant, or make any
          representations with respect to Third-Party Links & Ads. You use all
          Third-Party Links & Ads at your own risk and should apply a suitable
          level of caution and discretion. When you click on any of the
          Third-Party Links & Ads, the applicable third party’s terms and
          policies apply, including the third party’s privacy and data-gathering
          practices.
          <br />
          <br />
          <strong>Other Users.</strong> You are solely responsible for all your
          own user content. The Company does not control user content, and you
          acknowledge and agree that the Company is not responsible for any user
          content, whether provided by you or by others. You agree that the
          Company will not be responsible for any loss or damage incurred as the
          result of any such interactions. If there is a dispute between you and
          any other user, the Company is not obligated to become involved.
          <br />
          <br />
          You hereby release and forever discharge the Company and our officers,
          employees, agents, successors, and assigns from, and hereby waive and
          relinquish, each and every past, present and future dispute, claim,
          controversy, demand, right, obligation, liability, action and cause of
          action of every kind and nature, that has arisen or arises directly or
          indirectly out of, or that relates directly or indirectly to, the
          Site. If you are a California resident, you hereby waive California
          Civil Code section 1542 in connection with the foregoing, which
          states: “A general release does not extend to claims which the
          creditor does not know or suspect to exist in his or her favour at the
          time of executing the release, which if known by him or her must have
          materially affected his or her settlement with the debtor.”
          <br />
          <br />
          <strong>Google DoubleClick DART Cookie.</strong> Google is one of the
          third-party vendors on our site. It also uses cookies, known as DART
          cookies, to serve ads to our site visitors based upon their visit to
          www.website.com and other sites on the internet. However, visitors may
          choose to decline the use of DART cookies by visiting the Google ad
          and content network Privacy Policy at the following URL –{' '}
          <Link
            href="https://policies.google.com/technologies/ads"
            target="_blank"
            component="a"
            display="inline"
            color="#FFFFFF"
          >
            https://policies.google.com/technologies/ads
          </Link>
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          Disclaimers
        </Typography>
        <Typography component="p">
          The site is provided on an “as-is” and “as-available”. The Company and
          our suppliers expressly disclaim any and all warranties and conditions
          of any kind, whether express, implied, or statutory, including all
          warranties or conditions of merchantability, fitness for a particular
          purpose, title, quiet enjoyment, accuracy, or non-infringement. We and
          our suppliers do not guarantee that the site will meet your
          requirements, will be available on an uninterrupted, timely, secure,
          or error-free basis, or will be accurate, reliable, free of viruses or
          other harmful code, complete, legal, or safe. If applicable the law
          requires any warranties with respect to the site; all such warranties
          are limited in duration to ninety (90) days from the date of first
          use.
          <br />
          <br />
          Some jurisdictions do not allow the exclusion of implied warranties,
          so the above exclusion may not apply to you. Some jurisdictions do not
          allow limitations on how long an implied warranty lasts, so the above
          limitation may not apply to you.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          Limitation on Liability
        </Typography>
        <Typography component="p">
          To the maximum extent permitted by law, in no event shall the Company
          or our suppliers be liable to you or any third party for any lost
          profits, lost data, costs of procurement of substitute products, or
          any indirect, consequential, exemplary, incidental, special or
          punitive damages arising from or relating to these terms or your use
          of, or incapability to use the site even if the Company has been
          advised of the possibility of such damages. Access to and use of the
          site is at your own discretion and risk, and you will be solely
          responsible for any damage to your device or computer system, or loss
          of data resulting therefrom.
          <br />
          <br />
          To the maximum extent permitted by law, notwithstanding anything to
          the contrary contained herein, our liability to you for any damages
          arising from or related to this Agreement, will at all times be
          limited to a maximum of the fees you have paid the Company in the
          previous 6 months to the alleged damages being quantified and agreed,
          The existence of more than one claim will not enlarge this limit. You
          agree that our suppliers will have no liability arising from or
          relating to this agreement.
          <br />
          <br />
          Some jurisdictions do not allow the limitation or exclusion of
          liability for incidental or consequential damages, so the above
          limitation or exclusion may not apply to you.
          <br />
          <br />
          <strong>Term and Termination.</strong> Subject to this Section, these
          Terms will remain in full force and effect while you use the Site. We
          may suspend or terminate your rights to use the Site at any time for
          any reason at our sole discretion, including for any use of the Site
          violating these Terms. Upon termination of your rights under these
          Terms, your Account and right to access and use the Site will
          terminate immediately. You understand that any termination of your
          Account may involve deletion of the user content associated with your
          Account from our live databases. The Company will not be liable for
          any termination of your rights under this Agreement. Even after your
          rights under this Agreement are terminated, the following provisions
          of these Terms will remain in effect: Sections 2 through 2.5, Section
          3 and Sections 4 through 10.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          Copyright Policy
        </Typography>
        <Typography component="p">
          The Company respects the intellectual property of others and asks that
          users of the Software and our site do the same. In connection with
          both, we have adopted and implemented a policy respecting copyright
          law that provides for removing any infringing materials and for the
          termination of users who are repeated infringers of intellectual
          property rights, including copyrights. If you believe that one of our
          users is, using the Software or our Site, is unlawfully infringing the
          copyright(s) in a work and wish to have the allegedly infringing
          material removed, the following information in the form of a written
          notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our
          designated Copyright Agent:
          <ul>
            <li>your physical or electronic signature;</li>
            <li>
              identification of the copyrighted work(s) that you claim to have
              been infringed;
            </li>
            <li>
              identification of the material on our Softwares that you claim is
              infringing and that you request us to remove;
            </li>
            <li>
              sufficient information to permit us to locate such material;
            </li>
            <li>your address, telephone number, and e-mail address;</li>
            <li>
              a statement that you have a good faith belief that use of the
              objectionable material is not authorized by the copyright owner,
              its agent, or under the law; and
            </li>
            <li>
              a statement that the information in the notification is accurate,
              and under penalty of perjury, that you are either the owner of the
              copyright that has allegedly been infringed or that you are
              authorized to act on behalf of the copyright owner.
            </li>
          </ul>
          Please note that, pursuant to 17 U.S.C. § 512(f), any
          misrepresentation of material fact in a written notification
          automatically subjects the complaining party to liability for any
          damages, costs and attorney’s fees incurred by us in connection with
          the written notification and allegation of copyright infringement.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          General
        </Typography>
        <Typography component="p">
          These Terms are subject to occasional revision, and if we make any
          substantial changes, we may notify you by sending you an e-mail to the
          last e-mail address you provided to us and/or by prominently posting
          notice of the changes on our Site. You are responsible for providing
          us with your most current e-mail address. If the last e-mail address
          you provided us is not valid, our dispatch of the e-mail containing
          such notice will nonetheless constitute effective notice of the
          changes described in the notice. Any changes to these Terms will be
          effective upon the earliest of thirty (30) calendar days following our
          dispatch of an e-mail notice to you or thirty (30) calendar days
          following our posting of notice of the changes on our Site. These
          changes will be effective immediately for new users of our Site.
          Continued use of our Site following notice of such changes shall
          indicate your acknowledgement of such changes and agreement to be
          bound by the terms and conditions of such changes.
          <br />
          <br />
          <strong>Applicability of Arbitration Agreement.</strong> All claims
          and disputes in connection with the Terms or the use of the Software
          provided by us, that cannot be resolved informally shall be resolved
          by binding arbitration on an individual basis under the terms of an
          Arbitration Agreement. Unless otherwise agreed to, all arbitration
          proceedings shall be held in English. This Arbitration Agreement
          applies to you and the Company and to any subsidiaries, affiliates,
          agents, employees, predecessors in interest, successors, assigns, and
          all authorized or unauthorized users or beneficiaries of the Software.
          <br />
          <br />
          <strong>
            Notice Requirement and Informal Dispute Resolution.
          </strong>{' '}
          Before either party may seek arbitration, the party must first send to
          the other party a written Notice of Dispute describing the nature and
          basis of the claim or dispute, and the requested relief. A Notice to
          the Company should be sent to: 112 Capitol Trl Newark Delaware 19711
          USA. After receiving the Notice, you and the Company will attempt to
          resolve the claim or dispute informally. If you and the Company do not
          resolve the claim or dispute within sixty (60) working days after the
          Notice is received, either party may begin an arbitration proceeding.
          The amount of any settlement offer made by any party may not be
          disclosed to the arbitrator until after the arbitrator has determined
          the amount of the award to which either party is entitled under and in
          accordance with this Agreement.
          <br />
          <br />
          <strong>
            Additional Rules for Non-Appearance-Based Arbitration.
          </strong>{' '}
          If non-appearance-based arbitration is elected, it shall be conducted
          by telephone, online, and/or solely on written submissions; the
          specific manner shall be chosen by the party initiating the
          arbitration. The arbitration shall not involve any personal appearance
          by the parties or witnesses unless otherwise agreed by the parties.
          <br />
          <br />
          <strong>Time Limits.</strong> If you or the Company pursues
          arbitration, the arbitration action must be initiated and/or demanded
          within the statute of limitations and any deadline imposed under the
          AAA Rules for the pertinent claim.
          <br />
          <br />
          <strong>Authority of Arbitrator.</strong> If arbitration is initiated,
          the arbitrator will, in accordance with this Agreement, a copy of
          which will be provided to them, decide your and the Company’s rights
          and liabilities, and the dispute will not be consolidated with any
          other matters or joined with any other cases or parties. The
          arbitrator shall issue a written award and statement of decision
          describing the essential findings and conclusions on which the award
          is based. The arbitrator has the same authority to award relief
          individually as a judge in a court of law would have. The arbitrator’s
          award is final and binding upon you and the Company.
          <br />
          <br />
          <strong>Waiver of Jury Trial.</strong> THE PARTIES HEREBY WAIVE THEIR
          CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN
          FRONT OF A JUDGE OR A JURY, instead, electing that all claims and
          disputes shall be resolved by arbitration under arbitration in
          accordance with this Agreement.
          <br />
          <br />
          <strong>Confidentiality.</strong> All aspects of the arbitration
          proceeding shall be strictly confidential. The parties agree to
          maintain confidentiality unless otherwise required by law. This
          paragraph shall not prevent a party from submitting to a court of law
          any information necessary to enforce this Agreement, to enforce an
          arbitration award, or to seek injunctive or equitable relief.
          <br />
          <br />
          <strong>Right to Waive.</strong> Any or all of the rights and
          limitations set forth in this Arbitration Agreement may be waived by
          the party against whom the claim is asserted. Such waiver shall not
          waive or affect any other portion of this Arbitration Agreement.
          <br />
          <br />
          <strong>Survival of Agreement.</strong> This Arbitration Agreement
          will survive the termination of your relationship with the Company.
          <br />
          <br />
          <strong>Claims Not Subject to Arbitration.</strong> Notwithstanding
          the foregoing, claims of defamation, violation of the Computer Fraud
          and Abuse Act, and infringement or misappropriation of the other
          party’s patent, copyright, trademark or trade secrets shall not be
          subject to any arbitration.
          <br />
          <br />
          In any circumstances where the foregoing arbitration permits the
          parties to litigate in court, the parties hereby agree to submit to
          the personal jurisdiction of the courts to Delaware, USA, for such
          purposes.
          <br />
          <br />
          The Software and or site may be subject to certain export control laws
          and, therefore, may be subject to export or import regulations in
          certain countries. You agree not to export, re-export, or transfer,
          directly or indirectly, any technical data acquired from the Company
          or any products utilizing such data violating any such export laws or
          regulations.
        </Typography>
        <Box height={200} />
      </Container>
    </Box>
  );
};

export default TermsAndConditionsPage;
