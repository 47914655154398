import React from 'react';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { colors } from '../../config/constants';

import { ReactComponent as SvgCheck } from './SvgCheck.svg';
import { ReactComponent as SvgUncheck } from './SvgUnCheck.svg';

const PagePricingView = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        py: 9,
        bgcolor: colors.white,
        minHeight: '100vh',
      }}
      maxWidth="xl"
      id="section-services"
    >
      <Stack alignItems="center">
        <Typography
          fontSize={40}
          fontWeight={700}
          colorscheme="neutral.800"
          textAlign="center"
        >
          {t('Open Transparent Fees')}
        </Typography>
        <Typography
          fontSize={40}
          fontWeight={700}
          colorscheme="chamoisee"
          textAlign="center"
        >
          {t('Based on Profit not Revenue')}
        </Typography>
      </Stack>
      <Box mt={8}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={4}
          >
            <Box
              py={5}
              px={3}
              className="animate__target "
            >
              <Typography
                fontSize={22}
                fontWeight={700}
                colorscheme="neutral.900"
              >
                {t('Bronze')}
              </Typography>
              <Typography
                fontSize={16}
                colorscheme="neutral.500"
                mt="10px"
                height="89px"
              >
                {t(
                  'Designed for in country non complex trades between two parties',
                )}
              </Typography>
              <Stack
                mt={0}
                mb={3}
                direction="row"
                alignItems="center"
              >
                <Typography
                  fontSize={56}
                  fontWeight={600}
                  colorscheme="neutral.900"
                >
                  3%
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={300}
                  colorscheme="neutral.600"
                  ml={0.5}
                >
                  {t('Fees')}
                </Typography>
              </Stack>
              <Button
                href="https://app.oodlmarket.com/auth/register"
                variant="outlined"
                sx={{
                  height: 44,
                  mb: 5,
                }}
                fullWidth
              >
                {t('Get Started Now')}
              </Button>
              <Stack spacing={1.5}>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Fiat Based Trades and Settlement')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Crypto Based Trades and Settlement')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Crypto OffRamp')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Standard Smart Contract')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Fiat Two Days Settlement')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgUncheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.400"
                  >
                    {t('Trade Manager')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgUncheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.400"
                  >
                    {t('Logistics Support')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgUncheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.400"
                  >
                    {t('Legal Support')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgUncheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.400"
                  >
                    {t('Customised Trade Builder')}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
          >
            <Box
              py={5}
              px={3}
              sx={{
                boxShadow: '0px 10px 25px 0px #CCD9FF',
                borderRadius: '8px',
              }}
              className="animate__target "
            >
              <Typography
                fontSize={22}
                fontWeight={700}
                colorscheme="neutral.900"
              >
                {t('Gold')}
              </Typography>
              <Typography
                fontSize={16}
                colorscheme="neutral.500"
                mt="10px"
                height={89}
              >
                {t('Perfect for cross border trades between two parties.')}
              </Typography>
              <Stack
                mt={0}
                mb={3}
                direction="row"
                alignItems="center"
              >
                <Typography
                  fontSize={56}
                  fontWeight={600}
                  colorscheme="neutral.900"
                >
                  4%
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={300}
                  colorscheme="neutral.600"
                  ml={0.5}
                >
                  {t('Fees')}
                </Typography>
              </Stack>
              <Button
                href="https://app.oodlmarket.com/auth/register"
                variant="outlined"
                sx={{
                  height: 44,
                  mb: 5,
                }}
                fullWidth
              >
                {t('Get Started Now')}
              </Button>
              <Stack spacing={1.5}>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Fiat Based Trades and Settlement')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Crypto Based Trades and Settlement')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Crypto OffRamp')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Standard Smart Contract')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Fiat Two Days Settlement')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Shared Trade Manager')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgUncheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.400"
                  >
                    {t('Logistics Support')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgUncheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.400"
                  >
                    {t('Legal Support')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgUncheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.400"
                  >
                    {t('Customised Trade Builder')}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
          >
            <Box
              py={5}
              px={3}
              className="animate__target "
            >
              <Typography
                fontSize={22}
                fontWeight={700}
                colorscheme="neutral.900"
              >
                {t('Platinum')}
              </Typography>
              <Typography
                fontSize={16}
                colorscheme="neutral.500"
                mt="10px"
                height={89}
              >
                {t(
                  'Tailored to multi jurisdictional cross border trades with multiple parties.',
                )}
              </Typography>
              <Stack
                mt={0}
                mb={3}
                direction="row"
                alignItems="center"
              >
                <Typography
                  fontSize={56}
                  fontWeight={600}
                  colorscheme="neutral.900"
                >
                  5%
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={300}
                  colorscheme="neutral.600"
                  ml={0.5}
                >
                  {t('Fees')}
                </Typography>
              </Stack>
              <Button
                href="https://app.oodlmarket.com/auth/register"
                variant="outlined"
                sx={{
                  height: 44,
                  mb: 5,
                }}
                fullWidth
              >
                {t('Get Started Now')}
              </Button>
              <Stack spacing={1.5}>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Fiat Based Trades and Settlement')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Crypto Based Trades and Settlement')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Crypto OffRamp')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Standard Smart Contract')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Fiat Two Days Settlement')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Dedicated Trade Manager')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Logistics Support')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Legal Support')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <SvgCheck />
                  <Typography
                    ml={2}
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="neutral.900"
                  >
                    {t('Customised Trade Builder')}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PagePricingView;
