import React from 'react';
import { ThemeProvider } from '@mui/material';

import AppRouter from './routers/app-router';
import theme from './config/theme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
};

export default App;
