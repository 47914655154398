import React from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { colors } from '../../config/constants';

import { ReactComponent as SvgCheck } from './SvgCheck.svg';

const PageTradingView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        bgcolor: colors.darkgold,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          minHeight="100vh"
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <Box
              maxWidth={576}
              p={5}
              className="animate__target "
            >
              <Typography
                fontSize={isXs ? 48 : 48}
                fontWeight={600}
                lineHeight={1.25}
                colorscheme="peachyellow"
              >
                {t('Revolutionizing Physical Commodity Trading')}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                mt={4}
                pl={2}
              >
                <SvgCheck />
                <Typography
                  ml={2}
                  fontSize={18}
                  lineHeight="28px"
                  colorscheme="white"
                >
                  {t('No Upfront Fees')}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                mt={2.5}
                pl={2}
              >
                <SvgCheck />
                <Typography
                  ml={2}
                  fontSize={18}
                  lineHeight="28px"
                  colorscheme="white"
                  flex={1}
                >
                  {t('Smart Contract Automation and Governance')}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                mt={2.5}
                pl={2}
              >
                <SvgCheck />
                <Typography
                  ml={2}
                  fontSize={18}
                  lineHeight="28px"
                  colorscheme="white"
                >
                  {t('Built-in Compliance')}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                mt={5}
                spacing={1.5}
              >
                <Button
                  href="#section-features"
                  variant="contained"
                  color="inherit"
                  sx={{
                    bgcolor: 'white',
                    width: 127,
                    height: 48,
                    color: `${colors['gray.700']}`,
                  }}
                >
                  {t('Learn more')}
                </Button>
                <Button
                  href="https://app.oodlmarket.com/auth/register"
                  variant="contained"
                  sx={{
                    bgcolor: `${colors.prussianblue}`,
                    width: 127,
                    height: 48,
                  }}
                >
                  {t('Get started')}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: isSm ? 'static' : 'absolute',
              bottom: 0,
              right: 0,
              height: '70%',
              mt: isSm ? 0 : '15%',
            }}
          >
            <img
              src="/images/4bf3116e33436a6bc318e5c2f3baa0ce.png"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'left top',
                borderRadius: 8,
                overflow: 'hidden',
                transform: 'translateX(30px)',
              }}
              alt="Trading View"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageTradingView;
