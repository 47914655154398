import React from 'react';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const PageLeadingView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage:
          "linear-gradient(1deg, #4E4E49 3.1%, rgba(0, 0, 0, 0.00) 99.1%), url('images/036aa5d14ce07ca161a06a85e65c98ec.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: 4,
        pt: 14,
      }}
    >
      <Box
        maxWidth={650}
        className="animate__animated animate__fadeInUp"
      >
        {/* <Typography
          fontSize={isXs ? 64 : 80}
          lineHeight="86px"
          letterSpacing="-4px"
          fontWeight={700}
          colorscheme="peachyellow"
          maxWidth={650}
        >
          {t('Premier Commodity Marketplace')}
        </Typography> */}
        <Typography
          fontSize={isSm ? 19 : 21}
          lineHeight={1.5}
          fontWeight="bold"
          colorscheme="almostwhite"
        >
          {t(
            'We help buyers and sellers, and industry partners navigate the challenges of global inflation, international trade barriers, currency devaluation, and institutional inertia, all of which contribute to the ongoing scarcity of commodities.',
          )}
          <br />
          <br />
          {t(
            'By addressing these supply-demand imbalances, we facilitate efficient transactions, helping to stabilize commodity values and manage the rising prices caused by heightened demand.',
          )}
        </Typography>
        <Button
          href="https://app.oodlmarket.com/auth/register"
          variant="contained"
          sx={{
            width: 160,
            height: 60,
            fontWeight: '600 !important',
            fontSize: '18px !important',
            mt: 5,
          }}
        >
          {t('Sign Up Now')}
        </Button>
      </Box>
    </Box>
  );
};

export default PageLeadingView;
