import React, { useEffect } from 'react';
import { Container, Box, Typography } from '@mui/material';

import PageNavigationHeader from './PageNavigationHeader';
import { colors } from '../../config/constants';

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Box
      sx={{
        bgcolor: colors.prussianblue,
        color: 'white',
      }}
    >
      <PageNavigationHeader />

      <Container>
        <Typography
          variant="h3"
          component="h3"
          pt={23.5}
          fontWeight={700}
        >
          Privacy Policy
        </Typography>
        <Typography
          component="p"
          pt={1}
          color={colors['gray.400']}
        >
          Last updated May 1, 2024
        </Typography>

        <Typography
          component="h4"
          variant="h4"
          pb={3}
          pt={6}
        >
          ACCEPTANCE POLICY
        </Typography>
        <Typography component="p">
          This Company, (“OMP”, “we”, “us” or “our”) Privacy Policy covers our
          treatment of personal or personally identifiable information
          (&quot;Personal Information&quot;), that may be collected about users
          and visitors of our site (“Users” or “you,” or “your”) when you are on
          our website and when you use our website (“Website”) or mobile
          application (“Mobile App”), collectively referred to as the
          “Software”. Except where stated otherwise, this policy does not apply
          to the practices of companies that we do not own or control or to
          individuals that are not under our supervisory control.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          AGE RESTRICTIONS
        </Typography>
        <Typography component="p">
          Use of the Software by anyone under 18 is expressly prohibited.
          Persons under 18 are not allowed to register with or in any way, use
          the Software. We do not knowingly collect Personal Information from or
          about anyone under the age of 18. If we discover that we have
          collected Personal Information from someone under 18, we will delete
          that information immediately. Please refer to our End User License
          Agreement and Terms of Software (License) for additional age
          verification and eligibility information.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          COLLECTION OF YOUR PERSONAL INFORMATION
        </Typography>
        <Typography component="p">
          We will collect Personal Information when you use the Software on our
          website or mobile application or when you visit cookie-enabled web
          pages. We may also log your IP address when you use our website or
          mobile application.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          USER ACCOUNT INFORMATION
        </Typography>
        <Typography component="p">
          We allow users to set up trade accounts or participate in our online
          communities. Under no condition will we share your user account
          information with another individual, business or government entity
          unless you have or wish to enter a trade with this party or we are
          required and compelled by law, court order, subpoena or other legal
          process. Personal Information may include but is not limited to, and
          where applicable, your:
          <ul>
            <li>Email and Login Credentials</li>
            <li>Name Date of Birth, Country of Origin, Gender</li>
            <li>Home or Delivery Phone Number, Address, and Zip Code</li>
            <li>Billing Address and Phone Number</li>
            <li>
              Institutional Information and identification of beneficial
              ownership
            </li>
            <li>
              Passport or National ID data, employment, and office location
              information
            </li>
            <li>Financial information</li>
            <li>
              Personal characteristics, including photographic image,
              handwriting or other unique biometric data.
            </li>
          </ul>
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          DEVICE-SPECIFIC INFORMATION
        </Typography>
        <Typography component="p">
          When you register or use the App, we collect device type within the
          mobile application and store this information in log files. We use
          this information to provide you with the most up-to-date applications
          and features or to advertise or promote products and software that may
          apply to your device type, as described in this Privacy Policy.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          TRACKING INFORMATION
        </Typography>
        <Typography component="p">
          Tracking information is collected and stored in log files as you
          navigate through our website or use the Software, including, but not
          limited to, trade history, delivery locations history, and goods
          tracking.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          GEOLOCATION DATA
        </Typography>
        <Typography component="p">
          If you use the Software through your mobile device, where applicable,
          we will collect your geo-location information to determine your
          location for fulfillment and compliance. You may no longer allow the
          App to use your location by turning this off at the device level.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          REFERRAL INFORMATION
        </Typography>
        <Typography component="p">
          If you invite others to use our website through invites and referrals,
          we will collect and retain the name and email address of the person
          receiving the invite to track referrals.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          COOKIES
        </Typography>
        <Typography component="p">
          To help us serve your needs better, we use “cookies” to store and
          track user information. A cookie is a small amount of data that is
          sent to your browser from a web server and stored on your computer’s
          hard drive. A website can use cookies to recognise repeat users or
          track web usage behavior. Cookies take up minimal room on your
          computer and cannot damage your computer’s files. Cookies assign a
          number to the user without meaning outside of the assigning website.
          Non-personal information and data may be automatically collected by
          virtue of the standard operation of our computer servers or by using
          cookies. If you do not want information collected through cookies,
          your browser allows you to deny or accept cookies. There may, however,
          be some features of the Software which require the use of cookies to
          customise the delivery of information to you. We do not control the
          use of third-party cookies (or any resulting information collected),
          and their use is not covered by our Privacy Policy. We do not have
          access to or control over these cookies.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          USE OF YOUR PERSONAL INFORMATION
        </Typography>
        <Typography component="p">
          To facilitate our operations, we may transfer, store, and process your
          information within our family of companies, partners, external
          Software providers based throughout the world. We contractually
          obligate recipients of your personal information to agree to at least
          the same level of privacy safeguards as required under applicable data
          protection laws. By communicating electronically with us, you
          acknowledge and agree to your personal information being processed in
          this way.
          <br />
          <br />
          <b>
            We use Personal Information collected pursuant to this Privacy
            Policy to:
          </b>
          <br />
          <br />
        </Typography>
        <Typography
          component="p"
          pl={3}
        >
          <strong>
            <u>PROVIDE THE SOFTWARE.</u>
          </strong>{' '}
          We use both personal and business Information, and any other
          information we collect from you to provide you with the Software, to
          support and enhance your use of the Software, to monitor our most
          commonly used features, to display your trade history, to display
          current trades, to allow you to rate trades, and to determine which
          trade features we need more of our focus or improvement.
          <br />
          <br />
          <strong>
            <u>EXPAND TO NEW MARKETS.</u>
          </strong>{' '}
          If the Software is not available in your geographic location, you will
          be presented with the option to provide us with your email address and
          zip code so that we may notify you when it becomes available.
          <br />
          <br />
          <strong>
            <u>FULFILLMENT OPTIMIZATION.</u>
          </strong>{' '}
          We use Personal Information and any other information we collect from
          you to determine where to offer or focus the Software, features,
          and/or resources.
          <br />
          <br />
          <strong>
            <u>PROMOTIONAL MATERIAL.</u>
          </strong>{' '}
          We may also track your past trades to provide you with a personalised
          profile of your trade history. In addition, we may notify you about
          new software or other information.
          <br />
          <br />
          <strong>
            <u>APPLICATION OPTIMIZATION.</u>
          </strong>{' '}
          We use Device-Specific Information to provide the correct application
          version depending on your device type and troubleshoot technical
          issues.
          <br />
          <br />
          <strong>
            <u>DIAGNOSE TECHNICAL PROBLEM.</u>
          </strong>{' '}
          We use your Internet Protocol (IP) address to help diagnose problems
          with our servers and to administer the Website and mobile application.
          Your IP address is used to gather broad demographic data. Your IP
          address contains no personal information about you.
          <br />
          <br />
          <strong>
            <u>SOFTWARE-RELATED ANNOUNCEMENTS.</u>
          </strong>{' '}
          We will send you strictly Software-related announcements on rare
          occasions when necessary. For instance, if our Software is suspended
          for maintenance, we might email you. As these are Software-related
          announcements and not promotional material, you may not opt out of
          these notices.
          <br />
          <br />
          <strong>
            <u>YOUR REGISTRATION.</u>
          </strong>{' '}
          We will send you a welcoming email to the address you provided at
          registration to verify your username and password. We will also
          communicate with you in response to any inquiries, Software requests,
          and any requests or inquiries regarding your account.
          <br />
          <br />
          <strong>
            <u>IDENTIFICATION OF AGE-RESTRICTED ACCOUNTS.</u>
          </strong>{' '}
          We use Personal Information to confirm that all Software users are at
          least 18. Any age-related issues that may arise will result in a flag
          and/or suspension of your account in our system. Repeated violations
          of the age restrictions in this Privacy Policy will result in
          disclosure to applicable authorities.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          USE OF NON-PERSONAL INFORMATION
        </Typography>
        <Typography component="h4">
          We use non-personal Information collected pursuant to this Privacy
          Policy for the purposes stated herein. As used herein, non-personal
          information may correspond to a particular person, account, or
          profile. We may also use or share this information with third parties
          for research, marketing, and other business purposes.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          TARGETED OR BEHAVIORAL ADVERTISING
        </Typography>
        <Typography component="h4">
          Targeted advertising (also known as Behavioral Advertising) uses
          information collected on an individual’s web browsing behaviour, such
          as the pages they have visited, the searches made, the type of device
          used, or orders placed. This information is then used to select which
          advertisement should be displayed to a particular individual via the
          Software. For example, if you have shown a preference for a product
          type while using the Software, you may be served an advertisement via
          the Software relating to that type of product. We may share or sell
          aggregated demographic information about our user base, including
          information related to your use of the Software (e.g., how you
          interact with our App and website, our partners and advertisers), your
          device type, UUID, purchase history, delivery location history, and
          click tracking, with third-party advertisers for the purpose of
          selecting an appropriate audience for their advertisements, but only
          after either removing from such information anything that personally
          identifies you or combining it with other information so that it no
          longer personally identifies you. This information does not identify
          individual users by name, email or other contact information.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          FACILITATE GENERAL IMPROVEMENTS TO THE SOFTWARE, WEBSITE OR MOBILE APP
        </Typography>
        <Typography component="h4">
          We strive to provide you with the best possible experience when using
          the Software, whether on the Website or mobile app. To that end, we
          use non-personal Information to assess the functionality of our
          Software and identify areas requiring additional resources or focus.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          DISCLOSURE OF YOUR INFORMATION
        </Typography>
        <Typography component="h4">
          We share collected Personal Information, or non-personal Information,
          from or about you only as set forth herein:
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          AGGREGATED DATA
        </Typography>
        <Typography component="h4">
          We may share or sell aggregated demographic or other information about
          our user base that includes non-personal Information and log data
          (including how users interact with our Mobile App and Website) with
          third parties for trade analysis, profiling, and to deliver targeted
          information about trades.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          THIRD-PARTY SOFTWARE PROVIDERS
        </Typography>
        <Typography component="h4">
          We use a third-party provider to host the ‘support’ section of the
          Software. Information collected within this section of the Software is
          governed by this Privacy Policy. Our third-party Software provider
          does not have access to this information. We may employ, at our
          discretion and without notice, third-party companies and individuals
          to facilitate our Software, to provide the Software on our behalf,
          provide You support, perform Website-related software (e.g., without
          limitation, maintenance Softwares, database management, web analytics
          and improvement of the Website’s features) or to assist us in
          analyzing how our Website and Software are used. Any access by these
          third parties to access to your Personal Information is provided only
          to perform these tasks on our behalf and is not retained by these
          third parties.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          PAYMENT/CREDIT CARD TRANSACTIONS
        </Typography>
        <Typography component="h4">
          All payments are processed by secure third-party payment processors
          and banks. We have no control over third-party payment processors or
          banks. Any information you provide to a third-party payment processor
          or bank is governed by such and is their separate privacy policy
          instead of this Privacy Policy. Please consult the privacy policy of
          the applicable third-party payment processor or bank if you wish to
          have details.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          LEGAL PROCESS
        </Typography>
        <Typography component="h4">
          We cooperate with federal, state, city, and local governments, law
          enforcement agencies and officials, and, whereby law and or court
          order, private parties to enforce and comply with any and all
          applicable laws related to or arising from the use of the Software. We
          will disclose any information about you to government or law
          enforcement officials or private parties as we, in our sole
          discretion, believe necessary or appropriate to respond to claims and
          legal processes (including but not limited to subpoenas), to protect
          the property and rights of our or a third party whose property or
          rights we are required to protect, as well as to protect the safety of
          the public or any person(s), or to prevent or stop activity we may
          consider to be illegal, unethical or that would otherwise expose us to
          legal action.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          ACQUISITION OF COMPANY
        </Typography>
        <Typography component="h4">
          If all or a substantial portion of the assets, business or stock of
          our company is acquired by, merged with or transferred to another
          party, or in the event that we go out of business or enter bankruptcy,
          your Personal Information would be one of the assets that is
          transferred to or acquired by a third party. You acknowledge that such
          transfers may occur and that any acquirer of us or our assets may
          continue to use your Personal Information pursuant to and as set forth
          in this Privacy Policy.
        </Typography>
        <Typography
          component="h5"
          variant="h5"
          py={2}
        >
          DATA TRANSFERRED OUT OF THE EU
        </Typography>
        <Typography component="h4">
          We rely primarily on Model Contractual Clauses approved by the
          European Commission to facilitate the international transfer of
          personal information collected in the EEA, the United Kingdom and
          Switzerland (“European Personal Information”), and any onward transfer
          of such information to the extent the recipients of the European
          Personal Information may be in a country that the EU considers not to
          provide an adequate level of data protection. This includes transfers
          from our EU-based operating entities to our US-based operating entity.
          We may also rely on an adequacy decision of the European Commission
          confirming an adequate level of data protection in the jurisdiction of
          the party receiving the information. We are responsible for processing
          personal information we receive and subsequently transfer to a third
          party acting as an agent on behalf. Before we share your information
          with any third party, we will enter into a written agreement that the
          third party provides at least the same level of protection for the
          personal information as required under applicable data protection
          laws.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          UPDATING, CORRECTING OR DELETING YOUR PERSONAL INFORMATION
        </Typography>
        <Typography component="p">
          If you wish to update your Personal Information or email preferences
          or no longer wish to use our Software, you may correct, delete, or
          amend your personal information and preferences by making the change
          on our user information page or by emailing us at
          info@oodlemarket.com. We will respond to your request as soon as
          possible, usually within 30 days. We will take commercially reasonable
          steps to implement your opt-out requests promptly, but you may still
          receive communications from us for up to 30 days as we process your
          request.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          RETENTION OF PERSONAL INFORMATION
        </Typography>
        <Typography component="p">
          We will retain your Personal Information for the period necessary to
          fulfil the purposes outlined in this Privacy Policy, but in any event,
          for no less than the minimum period required by law and for no longer
          the maximum period allowed by law.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          SECURITY
        </Typography>
        <Typography component="p">
          All information we collect is securely stored within our database, and
          we use standard, industry-wide, Payment Card Industry-compliant,
          commercially reasonable security practices. However, as effective as
          encryption technology is, no security system is impenetrable. We
          cannot guarantee the security of our database, nor can we guarantee
          that the information you supply won’t be intercepted while being
          transmitted to us over the Internet, and any information you transmit
          to us, you do at your own risk. We recommend that you use unique
          numbers, letters and special characters in your password and not
          disclose your password to anyone. If you do share your password or
          personal information with others, you are responsible for all actions
          taken in the name of your account. If your password has been
          compromised for any reason, you should immediately notify us and
          change your password.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          PUBLIC FORUMS
        </Typography>
        <Typography component="p">
          Our Website and Mobile App may offer publicly accessible blogs or
          community forums. Any information you provide in these areas may be
          read, collected, and used for any purpose by others who access them,
          including but not limited to us. We are not responsible for the
          personally identifiable or other information you choose to submit in
          these areas. To request the removal of your personal information from
          our blogs or community forums, contact us at info@oodlemarket.com.
          Please be aware that we may not always be able to remove your Personal
          Information once it is posted in a public forum, so be very
          considerate before doing so.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          py={3}
        >
          AMENDMENTS AND ALT 5 SIGMA CONTACT
        </Typography>
        <Typography component="p">
          Amendments. We reserve the right to amend this policy at any time. We
          will contact registered users by e-mail, and shall also post a notice
          of changes on our website, when and if the terms of this Privacy
          Policy are amended.
        </Typography>
        <Box height={200} />
      </Container>
    </Box>
  );
};

export default PrivacyPolicyPage;
