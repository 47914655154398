import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import AppFooter from './AppFooter';

const AppMainLayout = () => {
  return (
    <Box>
      <Box
        sx={{
          minHeight: '80vh',
        }}
      >
        <Outlet />
      </Box>
      <AppFooter />
    </Box>
  );
};

export default AppMainLayout;
