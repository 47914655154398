/* eslint-disable no-undef */

export const colors = {
  'gray.000': '#FFFFFF',
  'gray.300': '#D7D7D7',
  'gray.400': '#969696',
  'gray.600': '#5C5C5C',
  'gray.700': '#344054',
  'gray.900': '#18181B',
  white: '#FFFFFF',
  almostwhite: '#EFEFEF',

  'footer.bg': '#2E2C2F',

  peachyellow: '#EDD29B',
  chamoisee: '#907947',
  davygray: '#4E4E49',
  prussianblue: '#0E2F44',
  black: '#020202',
  darkgold: '#8C794E',
  lightgold: '#EDD29B',

  'neutral.400': '#A0ABBB',
  'neutral.500': '#64748B',
  'neutral.600': '#64748B',
  'neutral.800': '#4B5768',
  'neutral.900': '#191D23',
};

export const settings = {
  isDevMode: process.env.NODE_ENV === 'development',
  isStageMode: process.env.NODE_ENV === 'stage',
  isProductionMode: process.env.NODE_ENV === 'production',
};
