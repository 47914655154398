import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { colors } from '../../config/constants';

const PageAboutUsView = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: colors.prussianblue,
      }}
      id="section-about-us"
    >
      <Grid
        container
        height="100%"
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            minHeight: '100vh',
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            minHeight="100%"
          >
            <Box
              maxWidth={650}
              p={5}
              className="animate__target "
            >
              <Typography
                fontSize={48}
                fontWeight={600}
                lineHeight="60px"
                colorscheme="peachyellow"
              >
                {t('OODL Token: The Future of Commodity Transactions')}
              </Typography>
              <Typography
                fontSize={21}
                lineHeight="28px"
                colorscheme="white"
                mt={4}
              >
                {t('$aboutus.content1')}
                <br />
                <br />
                {t('$aboutus.content2')}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
        >
          <img
            src="/images/775a8138e8689857e97f7f052fe5da2f.png"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            alt="Containers"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageAboutUsView;
