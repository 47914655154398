import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  IconButton,
  Link,
  ListItemText,
  MenuItem,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
  Menu,
  Typography,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { colors } from '../../config/constants';

const LinkTypography = styled(Link)(() => ({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: 1.5,
  textDecoration: 'none',
  color: colors.white,
}));

const PageNavigationHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [anchor, setAnchor] = useState(null);

  const handleOpen = (ev) => {
    setAnchor(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  useEffect(() => {
    const hash = location.hash;

    let el = null;
    if (hash) {
      el = document.getElementById(hash.replace('#', ''));
    }
    if (el) {
      window.scrollTo({
        top: el.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [location.hash]);

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 99,
        left: 0,
        top: 0,
        width: '100%',
        bgcolor: 'transparent',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 5,
          px: 3,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={isSm ? 2 : 5}
        >
          <img
            alt="logo"
            src="/images/logo.png"
          />
          {isSm ? (
            <></>
          ) : (
            <>
              <LinkTypography
                component={NavLink}
                to="/#section-about-us"
              >
                <Typography>{t('About Us')}</Typography>
              </LinkTypography>
              <LinkTypography
                component={NavLink}
                to="/#section-services"
              >
                <Typography>{t('Services')}</Typography>
              </LinkTypography>
              <LinkTypography
                component={NavLink}
                to="/#section-features"
              >
                <Typography>{t('Features')}</Typography>
              </LinkTypography>
            </>
          )}
        </Stack>
        {isSm ? (
          <>
            <IconButton
              sx={{
                color: 'white',
              }}
              onClick={handleOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleClose}
            >
              <MenuItem>
                <LinkTypography
                  component={NavLink}
                  to="#section-about-us"
                  sx={{ color: colors.darkgold }}
                >
                  <Typography>{t('About Us')}</Typography>
                </LinkTypography>
              </MenuItem>
              <MenuItem>
                <LinkTypography
                  component={NavLink}
                  to="#section-services"
                  sx={{ color: colors.darkgold }}
                >
                  <Typography>{t('Services')}</Typography>
                </LinkTypography>
              </MenuItem>
              <MenuItem>
                <LinkTypography
                  component={NavLink}
                  to="#section-features"
                  sx={{ color: colors.darkgold }}
                >
                  <Typography>{t('Features')}</Typography>
                </LinkTypography>
              </MenuItem>
              <MenuItem>
                <ListItemText>
                  <Link href="https://app.oodlmarket.com/auth/register">
                    {t('Sign Up')}
                  </Link>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <Link href="https://app.oodlmarket.com/auth/login">
                  {t('Login')}
                </Link>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1.5}
          >
            <Button
              href="https://app.oodlmarket.com/auth/register"
              sx={{
                width: 109,
                height: 50,
                fontSize: 16,
                fontWeight: 600,
              }}
              variant="contained"
            >
              {t('Sign Up')}
            </Button>
            <Button
              href="https://app.oodlmarket.com/auth/login"
              sx={{
                width: 109,
                height: 50,
                fontSize: 16,
                color: 'black !important',
                bgcolor: `${colors.peachyellow} !important`,
              }}
              variant="contained"
            >
              {t('Login')}
            </Button>
          </Stack>
        )}
      </Container>
    </Box>
  );
};

export default PageNavigationHeader;
