import React from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { colors } from '../../config/constants';

import { ReactComponent as SvgCheck } from './SvgCheck.svg';

const PageDebitCardView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall1 = useMediaQuery('(max-width: 1440px)');
  const isSmall2 = useMediaQuery('(max-width: 1240px)');

  return (
    <Box
      sx={{
        bgcolor: colors.prussianblue,
      }}
      id="section-contact"
    >
      <Grid
        container
        overflow="hidden"
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <Box
              maxWidth={476}
              p={5}
              className="animate__target "
            >
              <Typography
                fontSize={isSm ? 48 : 48}
                lineHeight="66px"
                colorscheme={colors.lightgold}
                fontWeight={600}
              >
                {t('How to Manage & Spend OODL Tokens')}
              </Typography>
              <Stack
                direction="row"
                mt={4}
              >
                <SvgCheck />
                <Box flex={1}>
                  <Typography
                    ml={2}
                    fontSize={18}
                    fontWeight={700}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t('Purchasing OODL Tokens')}
                  </Typography>
                  <Typography
                    ml={2}
                    fontSize={18}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t(
                      'Acquire OODL tokens directly using USD, EUR, MATIC, BTC, ETH, USDT or USDC for large transactions.',
                    )}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction="row"
                mt={2.5}
              >
                <SvgCheck />
                <Box flex={1}>
                  <Typography
                    ml={2}
                    fontSize={18}
                    fontWeight={700}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t('Onboarding & Account Management')}
                  </Typography>
                  <Typography
                    ml={2}
                    fontSize={18}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t(
                      'Includes a thorough buyer seller verification process to ensure secure and compliant interactions.',
                    )}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction="row"
                mt={2.5}
              >
                <SvgCheck />
                <Box flex={1}>
                  <Typography
                    ml={2}
                    fontSize={18}
                    fontWeight={700}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t('Connecting & Whitelisting Wallets')}
                  </Typography>
                  <Typography
                    ml={2}
                    fontSize={18}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t(
                      'Set up and link crypto wallets to receive, send and store OODL tokens seamlessly.',
                    )}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction="row"
                mt={2.5}
              >
                <SvgCheck />
                <Box flex={1}>
                  <Typography
                    ml={2}
                    fontSize={18}
                    fontWeight={700}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t('Making Payments with OODL')}
                  </Typography>
                  <Typography
                    ml={2}
                    fontSize={18}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t(
                      'Use OODL tokens to purchase and access high-value commodities and services.',
                    )}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction="row"
                mt={2.5}
              >
                <SvgCheck />
                <Box flex={1}>
                  <Typography
                    ml={2}
                    fontSize={18}
                    fontWeight={700}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t('Trading Commodities')}
                  </Typography>
                  <Typography
                    ml={2}
                    fontSize={18}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t(
                      'Exchange commodities using OODL tokens within the platform.',
                    )}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction="row"
                mt={2.5}
              >
                <SvgCheck />
                <Box flex={1}>
                  <Typography
                    ml={2}
                    fontSize={18}
                    fontWeight={700}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t('OODL on Crypto Wallets')}
                  </Typography>
                  <Typography
                    ml={2}
                    fontSize={18}
                    lineHeight="28px"
                    colorscheme="white"
                  >
                    {t(
                      'You can receive, send and store OODL on any polygon-compatible wallet. We recommend you use Trust Wallet.',
                    )}
                  </Typography>
                </Box>
              </Stack>
              <Button
                href="https://app.oodlmarket.com/auth/register"
                variant="contained"
                sx={{
                  width: 160,
                  height: 60,
                  fontWeight: '600 !important',
                  fontSize: '18px !important',
                  mt: 5,
                }}
              >
                {t('Sign Up Now')}
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <Stack
            justifyContent="center"
            alignItems={isSm ? 'center' : 'flex-start'}
            minHeight="100vh"
            py={5}
          >
            <Box
              sx={{
                position: 'relative',
                width: 470,
                height: 900,
              }}
            >
              <img
                src="/images/phone-debit.png"
                alt="Phone"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
              <img
                src="/images/debit-card.png"
                alt="Card"
                style={{
                  position: 'absolute',
                  left: '40%',
                  top: '30%',
                  ...(isSmall1 && {
                    left: '20%',
                  }),
                  ...(isSmall2 && {
                    left: '5%',
                  }),
                }}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageDebitCardView;
